.post-card-main-container {
  max-width: 484px;
  padding: 0 20px;
  margin: 0 auto;
}

.disabled {
  pointer-events: none;
}

.post-card-container {
  background-color: rgb(241, 241, 241);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
}

.post-card-design-preview {
  position: relative;
}

.post-card-design-img {
  width: 100%;
  height: auto;
}

.post-card-design-button {
  position: absolute;
  top: 35%;
  left: 90%;
}

.post-card-design,
.post-card-logo,
.post-card-names,
.post-card-selfie,
.post-card-message,
.post-card-doodle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 444px;
  margin: 0 auto;
}

.post-card-design-img,
.post-card-logo-img,
.post-card-selfie-img,
.post-card-doodle-img {
  width: 100%;
}

.post-card-names-img {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0 10%;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.post-card-design-header,
.message-to,
.message-from {
  z-index: 1;
  margin: 3px 0;
  font-size: clamp(12px, 4vw, 18px);
  font-family: 'Mulish';
  color: #5133b2;
  width: 100%;
}

.message-to .editable-field,
.message-from .editable-field {
  display: inline-block;
  max-width: 40%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.message-selfie-container {
  position: absolute;
  pointer-events: none;
}

.message-selfie {
  z-index: 4;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.message-selfie-full {
  z-index: 4;
  width: 100%;
}

.selfie-fullscreen-button {
  z-index: 3;
  position: absolute;
  bottom: 14%;
  right: 52%;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  padding: 5px;
}

.selfie-edit-button {
  z-index: 3;
  position: absolute;
  bottom: 14%;
  left: 52%;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  padding: 5px;
}

.selfie-close-button {
  z-index: 5;
  position: absolute;
  top: 2%;
  left: 2%;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
}

.post-card-message-img {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding: 5% 10%;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.message-text {
  z-index: 1;
  font-size: 1rem;
  font-family: 'Mulish';
  margin: 0;
  text-align: center;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.placeholder-message {
  color: rgb(160, 160, 160);
}

.choose-prewritten-message-button {
  z-index: 1;
  margin: 10px auto 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  padding: 7px 15px;
  font-size: 1rem;
}

.choose-prewritten-message-button:focus {
  outline: none;
}

.message-modal-open-button {
  z-index: 1;
  margin: 10px auto 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  padding: 7px 15px;
  font-size: 1rem;
}

.message-modal-open-button:focus {
  outline: none;
}

.message-doodle {
  z-index: 1;
  position: absolute;
  top: 5%;
  margin: 0;
  height: 60%;
  border-top: 1px solid #2b08a0;
  border-bottom: 1px solid #2b08a0;
}

.message-doodle-background {
  position: absolute;
  top: 5%;
  margin: 0;
  width: 70%;
  height: 60%;
  background-color: white;
  border-radius: 10px;
  border: 1px solid #2b08a0;
}

.doodle-edit-button {
  z-index: 3;
  position: absolute;
  bottom: 40%;
  right: 16%;
  margin-left: 50px;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  padding: 5px;
}

.post-card-design-button,
.edit-button {
  margin-top: -0.25rem;
  margin-left: 5px;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  padding: 5px;
}

.editable-field {
  border: 1px solid #2b08a0;
  background-color: white;
  border-radius: 5px;
  padding: 3px;
}

.privacy-section {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  align-items: center;
  flex-wrap: wrap;
}

.privacy-section p {
  margin: 0;
  font-size: 1rem;
  font-family: 'Mulish';
  color: black;
}

.privacy-help-button {
  margin: 0 5px 2px 5px;
}

input.privacy-toggle {
  position: relative;
  appearance: none;
  outline: none;
  width: 50px;
  height: 30px;
  background-color: #ffffff;
  border: 1px solid #d9dadc;
  border-radius: 50px;
  box-shadow: inset -20px 0 0 0 rgb(231, 231, 231);
  transition-duration: 200ms;
}

input.privacy-toggle:after {
  content: '';
  position: absolute;
  top: 1px;
  left: 1px;
  width: 26px;
  height: 26px;
  background-color: transparent;
  border-radius: 50%;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
}

input.privacy-toggle:checked {
  border-color: #2b08a0;
  box-shadow: inset 20px 0 0 0 #2b08a0;
}

input.privacy-toggle:checked:after {
  left: 20px;
  box-shadow: -2px 4px 3px rgba(0, 0, 0, 0.05);
}

.save-button {
  color: white;
  display: block;
  margin: 20px auto;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: clamp(12px, 4vw, 20px);
  width: 100%;
  height: 55px;
}

.save-button:focus {
  outline: none;
}