@font-face {
    font-family: 'SF Pro Display';
    src: url('../../assets/fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

.mainContainer {
	margin: 0% 0% 0% 0%;
	padding: 3% 0% 1% 0%;
	background-color: white;
	position: sticky;
	width: 100%;
	top: 0;
	z-index: 99;
	min-height: 50px;
	border-bottom: 1px solid #8c8c8ca2;
	padding-top: 10px;
	padding-bottom: 10px;
}

.mainTitle {
	font-family: 'SF Pro Display';
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	margin: 0;
	padding: 0;
	color: #6C40FC;
	letter-spacing: 0.02em;
}

.contentContainer {
	display: flex;
	width: 100%;
	padding: 0px 15px;
	justify-content: space-between;
	align-items: center;
}

.button {
	background: #5133b2;
	color: #fff;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	text-align: center;
	outline: none;
	height: 31px;
	min-width: 98px;
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: space-around;
}

.options {
	color: #9c9c9c;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	border-bottom: 2px solid #e5e5e5;
}

.selectedOption {
	color: #000000;
	font-family: Montserrat;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 15px;
	letter-spacing: 0px;
	text-align: left;
	border-bottom: 2px solid #e5e5e5;
}

.refreshIcon {
	width: 25px;
	height: 25px;
	object-fit: contain;
	cursor: pointer;
}

.dropdownMenu {
	position: absolute;
	top: 50px;
	right: 0;
	width: 100%;
	z-index: 99;
	border: 1px solid rgba(0, 0, 0, 0.04);
	box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
	background: #ffffff;
	border-radius: 0px;
	padding: 0 10px;
}

.filterMainContainer {
	position: relative;
	display: inline-block;
}

.arrow {
	width: 12px;
	height: 12px;
	object-fit: contain;
}
