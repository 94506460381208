.input-field {
    font-size: 18px;
    border-radius: 0;
    border-top: 0;
    border-bottom: 3px solid #00000060;
    border-left: 0;
    border-right: 0;
    background-color: white;

}

.header-text {
    font-size: 18px;
    opacity: 60%;
}