.animation-container {
  overflow: hidden;
}

.post-card-animation-container {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.giftbox-animation-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 85vh;
}

.occasion-text {
  position: absolute;
  z-index: 1;
  text-align: center;
  font-family: 'Mulish', sans-serif;
  color: rgb(134, 137, 253);
  width: 100%;
}

.gift-box {
  z-index: 0;
  position: absolute;
  width: 100%;
  min-width: 320px;
  max-width: 588px;
}
