.navbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 1;
  width: 96%;
  margin: 0.75% 0% 0.75% 2%;
}

@media (max-width: 800px) {
  .navbar {
    margin: 2% 0% 2% 2%;
  }
}
