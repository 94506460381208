
.main-container{
    display:flex;
    background-color: white;
    padding-right: 1em;
    padding-left:1em;
    padding-bottom:2em;
    flex-direction: column;
}
.main-container-div1{
    display:flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}
ol{
    padding: 0;
    list-style-type: none;
}
li{padding: 0;}
.main-container-title{
    display:flex;
    width: 100%;
    height: 100%;
    flex-direction: row;

}
.main-container-title-text{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size:1.3em;
    font-style:bold;
    font-family: 'Mulish';
    color:rgba(94, 134, 253, 0.831);
    margin-left: 1em;

}
.main-container-payout-div{
    background-color:green;
    width:58%;
    display: flex;
    justify-content:center;
    align-items: center;
    padding-left: 0.4em;
    padding-right: 0.4em;
}
.main-container-payout-text{
color:white;
}
.main-container-div2{
margin-left:.3em;
margin-right:.3em;
margin-top:1.3em;
margin-bottom: 1.3em;
}
.main-container-delivery-notes{
font-family: "Mulish";
font-size:1.1em ;
color:rgba(24, 23, 23, 0.731);
}
.task-product-title{
    font-size: 1.2em;
}

.task-product-pickup-button{
    z-index: 1;
    color: white;
    background-color: rgba(94, 134, 253, 0.831);
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    font-size: 1.1em;
    width: 30%;
    height: 100%;
    display:flex;
    align-items: center;
    justify-content: center;
}
.task-product-title-div{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: center;
    
}
.task-product-main-container{
  border-radius: 15px;
   
    /* padding-left: 10px;
    padding-right: 10px; */
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
}
.task-product-grunner-info{
    white-space: pre-line;
}
.main-container-inner{
    margin-top: 1.55em;
}
.main-container-ol{
  margin-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
  /* padding-top: 2px; */
  padding-bottom: 5px;
  border-radius: 15px;
  background-color: #f5f5f5;
    
}
.main-container-delivery-details{
    margin-top: 1.3em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.main-container-delivery-details-left{
    display: flex;
    width: 40%;
    flex-direction: column;
}
.main-container-delivery-details-right{
    display: flex;
    width:60%;
    flex-direction: column;

}
.main-container-delivery-details-text{
    font-family: "Mulish";
    font-weight: bold;
    font-size: 1.2em;
}
.next-button {
    margin-top:3em;
    z-index: 1;
    
    left: 0;
    right: 0;

    color: white;
    background-color: #2b08a0;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    font-size: 20px;
    width: 100%;
    height: 55px;
  }
  .main-container-delivery-details-container{
    padding-bottom: 0.5em;
  }
  .main-container-list-item{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    
  }
  .task-merchant-button{
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
    
    left: 0;
    right: 0;
    
    margin-top: 4px;

    color: white;
    background-color: #2b08a0;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    font-size: 20px;
    width: 100%;
    height: 55px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .task-merchant-title{
    font-size: 1.2em;
    margin-top: 20px;
    font-weight: bold;
  }
  .task-merchant-div-pickup-button{
    width: 120px;
  }
  .task-merchant-button-picked{
    padding-top: 10px;
    padding-bottom: 10px;
    z-index: 1;
    
    margin-top: 4px;
    left: 0;
    right: 0;
    font-weight: bold;
    color: black;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    /* box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35); */
    font-size: 20px;
    width: 100%;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
  }
  .task-product-title{
    font-size: 1em;
   
    font-weight: bold;
  }
  .task-product-note-text{
    font-size: 1em;
    font-weight: bold;
    color: rgba(24, 23, 23, 0.631);
  }
  .task-merchant-subtitle{
    padding-bottom: 10px;
  }
  .task-merchant-title-div-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    min-width: 260px;
    border-radius: 15px; 
     margin-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: white;
  }
  .task-product-notes{
    color: rgba(24, 23, 23, 0.631);
  }
  .task-merchant-title-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    padding-bottom: 10px;
    /* border-radius: 15px 15px 0px 0px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    
    background-color: white; */
  }
  .task-merchant-title-div2{
    /* border-radius: 0 0 15px 15px; */
    /* padding-left: 10px;
    padding-right: 10px;
    
    padding-bottom: 10px;
    background-color: white; */
  }
  .main-container-divider-merchant{
    min-height: 30px;
    height: 30px;
    width: 100%;
    
  }
  .task-merchant-div-upper-divider{
    height: 5px;
    width: 100%;
  }