@font-face {
	font-family: 'SF Pro Display Medium';
	src: url('../../../OrderHistory/assets/fonts/SFPRODISPLAYMEDIUM.OTF') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.heading{
    margin: 0% 0% 0% 0%;
	padding: 3% 0% 1% 0%;
	background-color: white;
	position: sticky;
	width: 100%;
	top: 0;
	z-index: 99;
	min-height: 50px;
	border-bottom: 1px solid #8c8c8ca2;
	padding-top: 10px;
	padding-bottom: 10px;
}

.headerText {
	font-family: 'SF Pro Display Medium';
	font-size: 18px;
	margin: 0;
	padding: 0;
	color: #6C40FC;
	letter-spacing: 0.02em;
	cursor: pointer;
}

.contentContainer {
	display: flex;
	width: 100%;
	padding: 0px 15px;
	justify-content: space-between;
	align-items: center;
}

.back_btn{
    width: 23px;
}