/* Navbar */
@media (max-width: 600px) {
  .main-header-chat {
    display: none;
  }

  .main-header-chat-show {
    display: none;
  }
}

@media (min-width: 601px) {
  .main-header-chat {
    display: none;
  }

  .main-header-chat-show {
    position: fixed;
    top: 0;
    width: 100%;
    height: 8vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px;
  }

  .main-header-chat-show img {
    width: 50px;
  }

  .space-below-navbar {
    height: 15vh;
  }
}
