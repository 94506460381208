.organize-sort-bar {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 13px;
	margin-bottom: 13px;
	margin-left: 15px;
	margin-right: 15px;
	flex: 0.4;
}
.orderhistory-divider {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.organize-sort-buttons {
	display: flex;
	flex-direction: row;
	height: 100%;
	align-items: center;
	justify-content: center;
	flex: 0.4;
}
.sort {
	background-color: #fff;
	border: none;
	color: #000;
	border-radius: 10px;
	margin-right: 10px;
	box-shadow: "none";
	flex: 0.25;
	height: 3.67em;
}
.vendor-histor-body {
	display: flex;
	width: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-left: 15px;
	padding-right: 15px;
}
.active {
	background-color: #000;
	border: none;
	color: #fff;
	border-radius: 10px;
	margin-right: 10px;
	flex: 0.25;
	height: 3.67em;
	box-shadow: "none";
}
.container-loading{
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.container {
	
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.status-header {
	margin-left: 10%;
	margin-right: 10%;
}
.top-bar-bar {
	display: flex;
	flex-direction: row;
	align-items: center;
	
}
.title {
	font-size: 1.6em;
	font-weight: bold;
}

.body-table-fixed {
	margin-top: 180px;
	width: 100%;
}
.heading-table-fixed {

	position: fixed;
	width: 100%;
	max-width: 1200px;
	align-self: center;

	top: 0;
	z-index: 1;

	background-color: #fff;
	padding-top: 2%;
	padding-bottom: 5px;
}
.inner-body-containter{
	width: 100%;
}
.heading-table {
	max-width: 1200px;
	width: 100%;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 1;
	left: 0;
	background-color: #fff;
	padding-top: 2%;
	padding-bottom: 5px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
	outline: none;
}
.headers {
	display: flex;
	flex-direction: row;
	justify-content: center;
	/* margin-top: 10px; */
	/* margin-bottom: 20px; */
	width: 100%;
	/* padding:13px 5% 13px  ; */
	background-color: white;
	margin-bottom: 10px;
	margin-top: 20px;
	padding-left: 15px;
	padding-right: 15px;
}
.top-bar {
	padding-top: 10px;
	margin-bottom: 10px;
	margin-left: 10px;
	margin-right: 10px;
	width: 100%;
}
.searchBox {
	margin-right: 10px;
	background-color: #ffffff;

	flex: 0.6;
}
.main-container {
	background-color: #fff;
	width: 100%;
	max-width: 1200px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.no-orders-img-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.no-orders-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.header-orders-img {
	height: 25px;
}
.no-orders-img {
	width: 50%;
	height: auto;
}
.vendor-histor-body {
	display: flex;
	width: 100%;

	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

.redirect {
	cursor: pointer;
	color: #000;
	text-decoration: none;
}
.redirect:visited:hover:active {
	cursor: pointer;
	color: #000;
	text-decoration: none;
}

/* a {
    color: inherit;
} */
