.cancel-button {
    color: #2b08a0;
    background-color: white;
    border: 1px solid #2b08a0;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 40%;
  }
  
  .okay-button {
    color: white;
    background-color: #2b08a0;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    padding: 10px;
    width: 40%;
  }
  