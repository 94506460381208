.main-container {
	display: flex;
	background-color: #ffffff;
	/* padding-left: 3em;
	padding-right: 3em; */
	flex-direction: column;
	max-width: 550px;
	width: 90%;
	justify-content: space-evenly;
	/* height: 100%; */
	flex: 7;
}

.grunner-input-field {
	padding-top: 1em;
}

.next-button {
	z-index: 1;
	bottom: 2rem;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
	max-width: 500px;
	margin-top: 20px;
	margin-bottom: 20px;
}

.policyContainer {
	z-index: 1;
	margin: 0 auto;
	font-family: "Mulish";
	font-size: 15px;
	width: 90%;
	max-width: 500px;
}
.loading {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.signup-signin-div {
	/* margin-top: 5vh; */
	display: flex;
	width: 100%;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.singup-signin-div-button {
	padding: 0;
	border: none;
	background: none;
}

.signup-signin-div-button-text {
	font-size: 1.25em;
	color: blue;
}

.link {
	color: #2b08a0;
}

.link:hover,
.link:focus,
.link:active {
	color: #2b08a0;
	text-decoration: underline;
	cursor: pointer;
}

.logo {
	width: 150px;
	object-fit: contain;
}

@media (max-width: 700px) {
	.logo {
		height: 80px;
	}

	.policyContainer {
		font-size: 14px;
	}
}
@media (max-width: 360px) {
	.link {
		font-size: 15px;
		height: auto;
	}

	.logo {
		height: 80px;
	}
	.policyContainer {
		font-size: 14px;
	}
}
