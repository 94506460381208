.screen-loading {
	z-index: 100;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform: -webkit-translate(-50%, -50%);
	transform: -moz-translate(-50%, -50%);
	transform: -ms-translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
}

.mainContainer {
	width: 90%;
	display: flex;
	flex-direction: column;
	align-items: center;
	max-width: 1000px;
	justify-content: center;
}

.logo {
	width: 150px;
	object-fit: contain;
	position: absolute;
	top: 0;
}

.text {
	font-family: "Mulish";
	font-size: 20px;
	min-height: 70px;
	--webkit-flex: 0;
	flex-shrink: 0;
	width: 100%;
	text-align: center;
}

.links {
	display: flex;
}

.storeLogos {
	width: 150px;
	margin: 0 50px;
	object-fit: contain;
}

@media (max-width: 700px) {
	.logo {
		height: 80px;
	}
	.text {
		font-size: 15px;
	}
	.storeLogos {
		margin: 0 5px;
	}
}
@media (max-width: 360px) {
	.logo {
		height: 80px;
	}
	.text {
		font-size: 15px;
	}
	.storeLogos {
		margin: 0 5px;
	}
}
