.post-card-main-container {
  width: fit-content;
  overflow: hidden;
  padding: 20px;
  margin: 0 auto;
}

.disabled {
  pointer-events: none;
}

.post-card-container {
  background-color: rgb(241, 241, 241);
}

.post-card-logo,
.post-card-names,
.post-card-selfie,
.post-card-message,
.post-card-doodle {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 280px;
  max-width: 444px;
  margin: 0 auto;
}

.post-card-logo-img,
.post-card-selfie-img,
.post-card-doodle-img {
  width: 100%;
}

.post-card-names-img {
  background-size: 100% 100%;
  padding: 0 10%;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.message-to,
.message-from {
  z-index: 1;
  margin: 0;
  font-size: clamp(12px, 4vw, 18px);
  font-family: 'Mulish';
  color: #5133b2;
  max-width: 80vw;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.message-selfie-container {
  position: absolute;
  pointer-events: none;
}

.message-selfie {
  z-index: 4;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.message-selfie-full {
  z-index: 4;
  width: 100%;
}

.selfie-fullscreen-button {
  z-index: 3;
  position: absolute;
  bottom: 14%;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
  padding: 5px;
}

.selfie-close-button {
  z-index: 5;
  position: absolute;
  top: 2%;
  left: 2%;
  color: white;
  background-color: #2b08a0;
  border-radius: 100%;
  filter: drop-shadow(0px 5px 5px rgba(0, 0, 0, 0.25));
}

.post-card-message-img {
  background-size: 100% 100%;
  padding: 5% 10%;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
}

.message-text {
  z-index: 1;
  font-size: 1rem;
  font-family: 'Mulish';
  margin: 0;
  text-align: center;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.message-modal-open-button {
  z-index: 1;
  margin: 10px auto 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
  padding: 7px 15px;
  font-size: 1rem;
}

.message-modal-open-button:focus {
  outline: none;
}

.message-doodle {
  z-index: 1;
  position: absolute;
  top: 5%;
  margin: 0;
  height: 60%;
  border-radius: 10px;
}

.message-doodle-background {
  position: absolute;
  top: 5%;
  margin: 0;
  width: 70%;
  height: 60%;
  background-color: white;
  border-radius: 10px;
}

.share-button {
  z-index: 1;
  position: absolute;
  bottom: 6%;
  right: 3%;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 20px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  width: 25%;
  height: 27%;
  font-size: clamp(12px, 4vw, 20px);
}

.share-button:focus {
  outline: none;
}

.share-loading {
  z-index: 6;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
}
