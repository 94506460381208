.feed-image-modal-container {
  width: 100%;
  border-radius: 12px;
}

.feed-image-modal-container .modal-content {
    background-color: transparent;
    border: none;
}

.feed-image-modal-container .modal-dialog .modal-content .modal-header {
    background-color: transparent;
    justify-content: flex-end;
    border-bottom: none;
}

.feed-image-modal-container .modal-dialog {
  padding: 16px;
}

.feed-image-modal-container .modal-dialog .modal-content .modal-header button.close {
    background-color: white;
    font-size: 24px;
    border-radius: 32px;
    width: 32px;
    height: 32px;
    opacity: 1;
    transition: all ease-in-out 0.3s;
    margin-right: 0px !important;
}


.feed-image-modal-container .modal-dialog .modal-content .modal-header button.close:hover {
    transform: scale(1.2);
}

.feed-image-modal-container .modal-body {
  padding: 0;
}

.feed-image-modal-container .feed-full-image {
    width: 100%;
    margin: 0 auto;
}