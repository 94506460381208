/* Update the CSS for your CreditCardInput component */
.container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: white; /* Set a white background */
	justify-content: c;
	padding: 10px;
    font-family: "Mulish";

}

.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 4px; /* Add rounded corners to the form */
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Add a shadow to the form */
	width: 100%;
	padding: 10px;
	max-width: 500px;
}

.label {
	display: flex;
	flex-direction: column;
	align-items: center; /* Center-align the content in the label */
	width: 100%;
}

.input {
	width: 100%;
    padding-bottom: 15px;
}

.row {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
    padding-bottom: 15px;

}

.inputRow {
    width: 48%;
}


.btnContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 15px;
}
.btn {
	background-color: #2b08a0;
	color: #fff;
	padding: 0.5rem 1rem;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	width: 60%;
}

.loading {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
  }
.pageloading {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
  }