.field{
  padding-bottom: 1em;
}
.input-field {
    font-size: 18px;
    border-radius: 0;
    border-top: 0;
    border-bottom: 3px solid #00000060;
    border-left: 0;
    border-right: 0;
    background-color: white;
    
  }
  .adorment{
    z-index: 9;
  }
  .header-text{
    font-size: 18px;
    opacity: 60%;
  }
  .handleOnClick-button{
    margin-top:3em;
    top: 16%;
    position: absolute;
    width: 70%;
    z-index: 2;
    opacity: 20;
  }