.appbar {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 99;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 15px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
}

.appbar-left {
  display: flex;
  justify-content: flex-start;
  flex: 1;
}

.appbar-right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}

.back-button {
  width: 23px;
  height: 23px;
}

.title {
  font-family: 'Mulish';
  color: #6c40fc;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  font-weight: normal;
}
