@font-face {
	font-family: "SF Pro Display";
	src: url("../../../OrderHistory/assets/fonts/SFPRODISPLAYREGULAR.OTF")
		format("truetype");
	font-weight: normal;
	font-style: normal;
}

.loader {
	height: 100%;
	width: 100%;
	display: flex;
	min-height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: white;
}

.noOrder {
	height: 60px;
	width: 100%;
}

.mainBack {
	height: 100%;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
}

.ssbackground {
	flex: 0.9;
	width: 100%;
	background-color: white;
	display: flex;
	align-items: center;
	flex-direction: column;
	padding-bottom: 20px;
	padding-left: 200px;
	padding-right: 200px;
	padding-top: 20px;
	position: relative;
	/* height: 90vh;
	height: 100%; */
	justify-content: space-between;
	max-width: 1200px;
}

.heading {
	margin: 0% 0% 0% 0%;
	padding: 3% 0% 1% 0%;
	background-color: white;
	position: sticky;
	width: 100%;
	top: 0;
	z-index: 99;
	min-height: 50px;
	border-bottom: 1px solid #8c8c8ca2;
	padding-top: 10px;
	padding-bottom: 10px;
}

.contentContainer {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
}

.heading2 {
	flex: 0.1;
	width: 100%;
	display: flex;
	align-items: center;
	background: white;
	position: sticky;
	top: 0;
	min-height: 50px;
}

.content {
	width: 100%;
	padding: 0px 15px;
}

.headerText {
	font-family: "SF Pro Display";
	font-size: 20px;
	color: #6c40fc;
	letter-spacing: 0.02em;
	line-height: 24px;
	text-align: center;
}

.back_btn {
	width: 23px;
}

.logo_image {
	width: 80px;
	height: 80px;
	margin-top: 25px;
	margin-bottom: 15px;
}

.greeting_text {
	/* Hi Gina: Ben just sent you a gift to be delivered on: */
	width: 100%;
	padding: 15px;
	text-align: left;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 120%;
	/* or 24px */
	color: #000000;
}

.note_text {
	width: 100%;
	padding: 15px;
	text-align: left;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 14px;
	line-height: 120%;
	/* or 24px */
	color: #000000;
}
.greeting_text_color2 {
	color: #5133b2;
}

.delivery_info_wrap {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 15px;
}

.schedule_wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: row;
	width: 100%;
	margin-bottom: 15px;
}
.delivery_wrap {
	display: flex;
	flex-direction: column;
	/* justify-content: space-between; */
	align-items: flex-start;
	width: 100%;
	margin-bottom: 15px;
}

.delivery_notes {
	width: 100%;
	min-height: 100px;
	height: auto;
	resize: none;
	background-color: "#fff";
	border-color: "#000000";
	border-width: 0.5;
	border-radius: 10;
	justify-content: "flex-start";
	text-align: "top";
	padding-top: 10;
	padding-left: 5;
	border-radius: 10px;
	box-shadow: 0px 0px 2px rgb(0 0 0 / 70%);
	margin-top: 20;
}

.schedule_text {
	font-family: Mulish;
	font-style: normal;
	font-weight: bolder;
	font-size: 20px;
	color: #5133b2;
	padding-bottom: 10px;
}

.schedule_btn {
	background: #2b08a0;
	border-radius: 50px;
	border-style: none;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	padding: 8px 25px;
	cursor: pointer;
}

.schedule_btn:hover {
	background-color: #1f0770;
}

.info_title_text {
	font-family: Mulish;
	font-style: normal;
	font-size: 20px;
	line-height: 25px;
	text-align: center;
	color: #5133b2;
}

.info_text {
	font-family: Mulish;
	font-style: normal;
	font-size: 15px;
	line-height: 25px;
	text-align: center;
	color: #3c3b6e;
	cursor: pointer;
}

.picker {
	border: none;
	background-color: transparent;
	text-align: center;
	color: #7e37d3;
	font-size: 20px;
	cursor: pointer;
}

.address_wrap {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding-left: 15px;
	padding-right: 15px;
}

.text2 {
	/* One line description */
	width: 100%;
	/* padding-left: 15px;
    padding-right: 15px; */
	padding-bottom: 10px;
	padding-top: 10px;
	font-family: Mulish;
	font-style: normal;
	text-align: left;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	color: black;
}

.address_title_text {
	font-family: Mulish;
	font-style: normal;
	font-size: 20px;
	line-height: 25px;
	text-align: start;
	width: 100%;
	color: #5133b2;
	padding-top: 15px;
	font-weight: bold;
	/* padding-left: 15px;
    padding-right: 15px; */
}

.verification_title_text {
	font-family: Mulish;
	font-style: normal;
	font-size: 20px;
	line-height: 25px;
	text-align: start;
	width: 100%;
	color: #7e37d3;
	/* padding-left: 15px;
    padding-right: 15px; */
}

.address {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
	cursor: pointer;
	background-color: #e5ddff;
	border-radius: 10px;
	height: 70px;
	padding: 15px;
	box-shadow: 2px 5px 4px rgba(0, 0, 0, 0.5);
}

.address_text {
	/* One line description */
	font-family: Mulish;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
	color: #3c3b6e;
	text-align: center;
	padding: 5px;
}

.mappin {
	width: 25px;
	height: 25px;
}

.edit {
	width: 25px;
	height: 25px;
}

.confirmed {
	width: 360px;
	height: 55px;
	/* margin-bottom: 25px; */
	/* position: absolute; */
	bottom: 0;
	background-color: #2b08a0;
	border-radius: 15px;
	border-style: none;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	/* identical to box height */
	text-align: center;

	color: #ffffff;
	cursor: pointer;
}

.confirmed:hover {
	background-color: #1f0770;
}

.remind {
	/* Remind Me Later */
	position: absolute;
	bottom: 0;
	margin-bottom: 25px;
	width: 180px;
	height: 20px;
	padding: 10px;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 13px;
	line-height: 16px;
	text-align: center;

	color: #ff0000;
	border-style: none;
	cursor: pointer;
	background-color: transparent;
}

.verify_wrap {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.tybackground {
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 50vh;
}

.thankyoubox {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 10%;
	margin: 5%;
	/* background-color: rgba(255, 255, 255, 0.5);
    border-radius: 10px; */
}

.thankyou_text {
	font-family: Mulish;
	font-style: normal;
	font-size: 25px;
	font-weight: bold;
	text-align: center;
	width: 100%;
	color: #2b08a0;
	margin-bottom: 15px;
	margin-top: 15px;
}

.thankyou_text2 {
	font-family: Mulish;
	font-style: normal;
	font-size: 15px;
	text-align: center;
	width: 100%;
	color: #2b08a0;
}

.download {
	width: 190px;
	height: 45px;
	margin-top: 15%;

	background-color: #2b08a0;
	border-radius: 18px;
	border-style: none;
	font-family: Mulish;
	font-style: normal;
	font-weight: bold;
	font-size: 15px;
	text-align: center;
	color: #ffffff;
	cursor: pointer;
}

.download:hover {
	background-color: #1f0770;
}

/*  Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
	.address {
		width: 600px;
		width: 100%;
	}
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
	.ssbackground {
		padding-left: 100px;
		padding-right: 100px;
	}
}

/*  Small devices (landscape phones, less than 768px) */
@media (max-width: 768px) {
	.ssbackground {
		padding-left: 18px;
		padding-right: 18px;
	}
	.address {
		width: 100%;
	}
}

/*  Small devices (landscape phones, less than 540px) */
@media (max-width: 540px) {
	.headerText {
		font-size: 18px;
	}

	.logo_image {
		margin-top: 15px;
		margin-bottom: 10px;
	}

	.heading_text {
		font-size: 16px;
	}

	.greeting_text {
		font-size: 14px;
		padding-bottom: 5px;
	}
	
	.schedule_wrap {
		padding-bottom: 5px;
	}
	.delivery_wrap {
		padding-bottom: 5px;
	}

	.schedule_text {
		font-size: 14px;
		padding-bottom: 5px;
	}

	.schedule_btn {
		font-size: 11px;
	}

	.info_title_text {
		font-size: 14px;
		line-height: 12px;
	}

	.address_title_text {
		font-size: 14px;
		line-height: 12px;
	}
	.info_text {
		font-size: 12px;
		line-height: 12px;
	}

	.text2 {
		font-size: 13px;
		padding-bottom: 5px;
		padding-top: 5px;
	}

	.address {
		width: 100%;
		margin-top: 10px;
		margin-bottom: 10px;
	}

	.address_title_text {
		line-height: 15px;
	}

	.address_text {
		font-size: 12px;
	}

	.logo_image {
		width: 60px;
		height: 60px;
	}

	.confirmed {
		width: 80%;
		border-radius: 10px;
		height: 45px;
		margin-top: 0px;
		font-size: 14px;
	}

	.schedule_btn {
		font-size: 10px;
		padding: 5px 18px;
	}

	.remind {
		padding: 5px;
		font-size: 12px;
		line-height: 5px;
	}
	.thankyou_text {
		font-size: 20px;
	}
}

@media (max-width: 300px) {
	.headerText {
		font-size: 16px;
	}
}
