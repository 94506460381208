/* Navbar */
.dropdown-toggle.btn.btn-success::after {
  display: none;
}

.dropdown-toggle.btn.btn-success .material-icons {
  font-size: 20px;
}

.main-header-feed-show {
  position: sticky;
  top: 0;
  width: 100%;
  height: max-content;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  padding-top: 4px;
  /* box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px; */
}
@media (max-width: 600px) {
  .main-header-feed {
    display: none;
  }

  .main-header-feed-show img {
    width: 50px;
  }

  .main-header-feed-content {
    text-align: center;
    position: relative;
    width: 100%;
  }

  #dropdown-basic-button {
    position: absolute;
    left: 17px;
    top: -47px;
    border-radius: 20px;
    font-size: 25px;
    padding: 10px 11px;
    background-color: #2b08a0;
    border: 0;
    outline: 0;
  }

  .space-below-navbar-feed-show {
    height: auto;
  }
}

@media (min-width: 601px) {
  .main-header-feed {
    display: none;
  }

  .main-header-feed-content {
    text-align: center;
    position: relative;
    width: 100%;
  }

  #dropdown-basic-button {
    position: absolute;
    left: 25px;
    top: -47px;
    border-radius: 20px;
    font-size: 25px;
    padding: 10px 11px;
    background-color: #2b08a0;
    border: 0;
    outline: 0;
  }

  .main-header-feed-show img {
    width: 50px;
  }

  .space-below-navbar-feed {
    height: 15vh;
  }

  .space-below-navbar-feed-show {
    height: auto;
  }
}
