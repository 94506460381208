.input {
	display: none;
}

.mainContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 5%;
}
.instructionsContainer {
	width: 90%;
	background-color: #eee;
	padding: 10px;
	border-radius: 15px;
}

.instructionsTitle {
	font-family: "Mulish";
	font-size: 20px;
	margin: 0;
	text-align: left;
}
.instructionsP {
	font-family: "Mulish";
	font-size: 18px;
	margin: 0;
}

.label {
	display: flex;
	font-family: "Mulish";
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
	margin-bottom: 20px;
	flex-shrink: 0;
}

.imgPermission {
	margin: 0 auto;
	margin-top: 10%;
	margin-bottom: 5%;
	width: 90%;
	text-align: right;
	color: blue;
	text-decoration: underline;
	width: 90%;
	text-align: right;
	font-size: 15px;
}
/* Image Preview Section */
.imageMainContainer {
	margin-top: 5%;
	margin-bottom: 5%;
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
	justify-content: center;
}
.image {
	margin: 15px auto;
	width: 50%;
	object-fit: contain;
}

.imageContainer {
	display: flex;
	flex-direction: column;
	font-family: "Mulish";
	justify-content: center;
	width: 90%;
}

.hrLine {
	border-style: none;
	border-top-style: solid;
	border-color: #eee;
	border-width: 2px;
	width: 90%;
	margin: 1% 0;
}

/* Modal */

.modalContainer {
	position: fixed;
	/* display: none; */
	z-index: 100;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	width: min(100%, 500px);
	height: 100vh;
	background-color: transparent;
}

.contentContainer {
	z-index: 120;
	top: 30%;
	left: min(5%, 250px);
	position: absolute;
	width: min(90%, 500px);
	height: max-content;
	padding: 20px;
	background-color: #f0f2ff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}

.modalText{
	color: #434343;
	font-family: "SF Pro Text";
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.02em;
	padding: 5px;
	margin-top: 5%;
	font-family: "Mulish";
}

.closeIcon {
	margin: 0 auto;
	border: none;
	position: absolute;
	right: 5;
	top: 5;
}


