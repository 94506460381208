.report-content-modal-container .modal-dialog {
  display: flex;
}

.report-content-modal-container .modal-content {
  border-radius: 20px;
  padding: 0px 16px;
}
.report-content-modal-container.fade.modal.show {
  width: 100%;
}

.report-content-modal-container.modal .modal-content {
  width: 100%;
  margin: 0 auto;
}

.report-content-modal-container.modal .modal-body {
  padding: 0;
}

.report-content-modal-container {
  /* background-color: transparent !important; */
}

.report-content-modal-each-button {
  background: transparent;
  display: block;
  width: 100%;
  outline: 0;
  border: 0;
  border-bottom: 1px solid #d9d9d9;
  margin: 0 auto;
  padding: 16px;
  font-size: 18px;
}
.report-content-modal-each-button.cancel {
  color: red;
}

.report-content-modal-each-button:last-child {
  border: 0;
}

@media (max-width: 600px) {
  .report-content-modal-container.fade.modal.show .modal-content {
    max-width: 350px;
    margin: 0 auto;
  }
}

@media (min-width: 601px) {
  .report-content-modal-container.fade.modal.show .modal-content {
    max-width: 380px;
    margin: 0 auto;
  }
}
