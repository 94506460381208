:root {
  --primary-color: #2b08a0;
  --secondary-color: #ffb538;
  --comp-color: #ffc285;
  --tertiary-color: #4c42b7;
  --background-color: #f4f4f4;
}

.screen_name {
  background-color: white;
  min-height: 100%;
}

.loader_consumer {
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 30%;
}

@font-face {
  font-family: "SF Pro Display";
  src: url("../../assets/fonts/SFPRODISPLAYREGULAR.OTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display Medium";
  src: url("../../assets/fonts/SFPRODISPLAYMEDIUM.OTF") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display medium italic";
  src: url("../../assets/fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Display light";
  src: url("../../assets/fonts/SF-Pro-Display-Light.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

.card_holder {
  /* background-color: rgb(245, 245, 245); */
  margin: 0% 0% 0% 0%;
  /* padding: 5% 0% 1% 0% ; */
  background-color: white;
  height: fit-content;
  min-height: 100vh;
  margin-left: 0.7%;
  margin-right: 0.7%;
}

span.violet {
  /* color: #2B08A0; */
  color: #5133b2;
  font-weight: 500;
  font-family: "SF Pro Display Medium";
  font-size: 18px;
}

.card_old {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  padding: 2% 2% 2% 2%;
  margin-left: 20%;
  margin-right: 20%;
  margin-bottom: 10px;
  border-style: none;
  border-radius: 8px;
  width: 60%;
  background-color: rgb(255, 255, 255);
  box-shadow: none;
  cursor: pointer;
  border: 1px solid #8c8c8c;
}

.filterMainContainer {
  position: relative;
  display: inline-block;
}

.to_consumer {
  color: black;
}

.navbar-feed-screen.new-navbar img {
  width: 60px;
  height: 60px;
}

.date {
  font-family: "SF Pro Display light";
  /* margin-left: .7%;  */
  font-size: 14px;
  color: #000000;
}

.status {
  font-family: "SF Pro Display medium italic";
  /* margin-left: .7%;  */
  color: #000000;
  font-size: 14px;
  margin-top: 15px;
}

.load {
  background-color: var(--primary-color);
  display: block;
  width: 8%;
  border: 0;
  color: rgb(248, 247, 247);
  margin: 4% auto;
  border-radius: 25px;
  padding: 1% 1% 1% 1%;
  text-align: center;
  left: 0;
  font-size: 14px;
}

.track_button_order {
  background-color: white;
  cursor: pointer;
  width: 35px;
}

a:hover {
  text-decoration: none;
}

.link_consumer {
  text-decoration: none;
}

.link_consumer:link:visited:hover:active {
  text-decoration: none;
}

.filterContainer {
  position: fixed;
  right: 0;
  bottom: 10%;
  z-index: 99;
}

.btn {
  background: var(--tertiary-color);
  color: #fff;
}

.dropdown {
  position: absolute;
  bottom: 100%;
  right: 0;
  width: 100px;
  z-index: 2;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  background: #fff;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

li {
  padding: 8px 12px;
}

/*
li:hover {
	background-color: rgba(0, 0, 0, 0.14);
	cursor: pointer;
}*/

.selectedStatus {
  background: var(--primary-color);
}

.noOrders {
  height: auto;
  max-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
  font-weight: 700;
}

@media (max-width: 820px) {
  .card_old {
    display: flex;
    padding: 4% 4% 4% 4%;
    margin-left: 0.7%;
    margin-right: 0.7%;
    margin-top: 0%;
    width: 98.6%;
    background-color: rgb(255, 255, 255);
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  span.violet {
    font-size: 16px;
  }

  .date {
    font-size: 12px;
  }

  .status {
    font-size: 12px;
  }
}
