.edit-card {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: min(100%, 500px);
  height: 100%;
  background-color: white;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.35);
}

.header-container {
  padding: 10px;
}

.close-button {
  z-index: 6;
  position: absolute;
  top: 10px;
  left: 10px;
}

.header-text {
  font-size: 20px;
  font-family: 'Mulish';
}

.content-container {
  padding: 50px 15px;
}

.prewritten-message {
  width: 100%;
  padding: 0.5rem 2rem;
  background: #cccbff;
  border-radius: 5px;
  border: none;
  color: #2b08a0;
  margin: 5px 0;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.message-add-icon {
  color: black;
  position: absolute;
  left: 1.5rem;
}
