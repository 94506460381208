@font-face {
	font-family: 'SF Pro Display bold';
	src: url('../../../OrderHistory/assets/fonts/SFPRODISPLAYBOLD.OTF') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Display Medium';
	src: url('../../../OrderHistory/assets/fonts/SFPRODISPLAYMEDIUM.OTF') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF Pro Display light';
	src: url('../../../OrderHistory/assets/fonts/SF-Pro-Display-Light.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('../../../OrderHistory/assets/fonts/SFPRODISPLAYREGULAR.OTF') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.StyledPage {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  line-height: 1.5;
  flex-shrink: 0;
}

.StyledPage::-webkit-scrollbar {
  display: none;
}

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
  flex-shrink: 0;
}

.StyledHeaderText {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: start;
  font-family: 'SF Pro Display bold';
  align-items: center;
  color: #6C40FC;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  flex-shrink: 0;
}

.recSpacing{
  margin-bottom: 10px;
}

.HeaderText {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  color: black;
  font-size: 14px;
  flex-shrink: 0;
  width: 100%;
}

.StyledText {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 17px;
  font-family: 'SF Pro Display light';
  letter-spacing: 0.02em;
  padding-bottom: 25px;
  padding-top: 10px;
  flex-shrink: 0;
  width: 100%;
}

.mediumText{
  font-family: 'SF Pro Display';
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding-bottom: 25px;
  padding-top: 10px;
}

.textbox{
  display: flex;
  flex-direction: column;
  padding-bottom: 25px;
  padding-top: 10px;
  flex-shrink: 0;
}

.boldText{
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 17px;
  font-family: 'SF Pro Display Medium';
  letter-spacing: 0.02em;
}

.regularText{
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 17px;
  font-family: 'SF Pro Display light';
  letter-spacing: 0.02em;
}

.handle{
  padding-top: 10px;
}

.viewBtn{
  width: 150px;
  height: 40px;
  background-color: #E5DDFF;
  font-family: 'SF Pro Display bold';
  color: #303030;
  border-radius: 10px;
  letter-spacing: 0.02em;
  font-size: 16px;
  line-height: 19px;
  border:none;
  margin-top: 15px;
  cursor: pointer;
}
.notifyBtn{
  width: auto;
  height: 40px;
  background-color: #E5DDFF;
  font-family: 'SF Pro Display bold';
  color: #303030;
  border-radius: 10px;
  letter-spacing: 0.02em;
  font-size: 0.7em;
  line-height: 19px;
  border:none;
  max-width: 50%;
  cursor: pointer;
}

.bottomSpacing{
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  flex-shrink: 0;
}

.modal{
  display: none; 
  margin-top: 15px;
  width: 100%;
  padding-bottom: 25px;
}

.modalContent {
  background-color:#F0F2FF;
  width: 100%;
  padding: 30px 20px 20px 20px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex-shrink: 0;
}

.modalHeader{
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  font-family: 'SF Pro Display';
  color: #4E4E4E;
  text-align: center;
}

.contentText{
  color: #8C8C8C;
  font-family: 'SF Pro Display';
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.02em;
}

.close {
  color: #aaaaaa;
  position:absolute;
  top:10px;
  right:15px;
  cursor: pointer;
}

.outer{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 25px;
  flex-shrink: 0;
}

.detailBox{
  height: auto;
  width: 100%;
  background: #F0F2FF;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  flex-shrink: 0;
}

.detailHeader{
  font-family: 'SF Pro Display';
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  color: #000000;
  padding-bottom: 10px;
}
.notifydetail{
  font-family:"Mulish";
  font-size: 14px;
  font-weight:700;
  line-height: 16px;
  letter-spacing: 0.021em;
  color: #000000;
  padding-bottom: 10px;
  padding-top: 10px;
}
.detailDivHeader{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  align-items: center;
}

.detailContent{
  font-family: 'SF Pro Display';
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #5133B2;
}

.methodText{
  font-family: 'SF Pro Display';
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #5133B2;
}

.giftbox{
  display: flex;
  flex-direction: row;
  padding-bottom: 25px;
  padding-top: 10px;
  width: 100%;
  flex-shrink: 0;
  align-items: flex-start;
}

.statusText{
  height: 24px;
  letter-spacing: 0.02em;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  font-family: 'SF Pro Text';
  color: #000000;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}

.giftImg{
  width: 75px;
  height: 75px;
  margin-right: 15px;
}

.giftText{
  font-family: 'SF Pro Display';
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
}

.summaryContent{
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  width: 100%;
  flex-shrink: 0;
}

.summaryText{
  align-items: center;
  color: black;
  font-size: 16px;
  line-height: 17px;
  font-family: 'SF Pro Display light';
  letter-spacing: 0.02em;
}
.modal-footer{
  justify-content: center;
}
.summaryTextBold{
  align-items: center;
  color: black;
  font-size: 17px;
  line-height: 17px;
  font-family: 'SF Pro Display bold';
  letter-spacing: 0.02em;
}

.line{
  border: 1px solid #8C8C8C;
  margin-top: 10px;
  margin-bottom: 10px;
}

.cardInfo{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 25px;
  padding-top: 10px;
  width: 100%;
  flex-shrink: 0;
}

.card{
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.glink{
  padding-top: 15px;
  padding-bottom: 30px;
}

.glinkBox{
  height: 50px;
  width: 100%;
  background: #F0F2FF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.02em;
  color: #5133B2;
  font-family: 'SF Pro Display bold';
  cursor: pointer;
  flex-shrink: 0;
}

@media (max-width: 400px) {
  .viewBtn{
    width: 120px;
    height: 35px;
    font-size: 14px;
    margin-top: 8px;
  }
  
  .handle{
    padding-top: 5px;
  }
  
  .container{
    padding-top: 20px;
  }
  .StyledHeaderText {
    font-size: 16px;
  }
  .StyledText {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 5px;
  }
  .mediumText {
    font-size: 14px;
    padding-bottom: 20px;
    padding-top: 5px;
  }
  .textbox{
    padding-bottom: 20px;
    padding-top: 5px;
  }
  .boldText{
    font-size: 14px;
  }
  
  .glinkBox{
    font-size: 16px;
  }

  .regularText{
    font-size: 14px;
  }

  .modal{
    margin-top: 8px;
  }

  .modalHeader{
    font-size: 16px;
  }
  
  .contentText{
    font-size: 14px;
  }

  .detailContent{
    font-size: 12px;
  }

  .giftText{
    font-size: 14px;
  }

  .methodText{
    font-size: 16px;
  }

}