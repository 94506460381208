:root {
  --primary-color: #2b08a0;
  --secondary-color: #ffb538;
  --comp-color: #ffc285;
  --tertiary-color: #4c42b7;
  --background-color: #f4f4f4;
}
.outer {
  height: 60vh;
  border-style: solid;
  border-color: #ffff;
  max-height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 5%;
}

.noOrderimg {
  width: 350px;
}

.noorderhistory_text {
  font: x-large "Mulish", sans-serif;
  color: #2b08a0;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 800px) {
  .noorderhistory_text {
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .noorderhistory_text {
    font-size: 14px;
  }
  .noOrderimg {
    width: 300px;
  }
}

@media (max-width: 300px) {
  .noOrderimg {
    width: 250px;
  }
}
