/* Global */
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap'); */

:root {
  --primary-color: #2b08a0;
  --secondary-color: #ffb538;
  --comp-color: #78787a;
  --background-color: #f4f4f4;
}

/** Alte Haas Grotesk TTF font */
@font-face {
  font-family: "Alte Haas Grotesk";
  src: url("../../assets/AlteHaasGroteskRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Alte Haas Grotesk Bold";
  src: url("../../assets/AlteHaasGroteskBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

.bold-font {
  font-family: "Alte Haas Grotesk Bold", sans-serif !important;
  font-weight: 700;
}

.feedsscreen-container {
  box-sizing: border-box;
  font-size: 18px;
  line-height: 21.47px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feedsscreen-container > div,
.feedsscreen-container header {
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
}

.feedsscreen-container * {
  font-family: "Alte Haas Grotesk", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
}

.feed-tabs {
  height: 56px;
}

.feed-tabs .nav-item .nav-link {
  text-decoration: none;
  font-weight: bold;
  color: #8f8e90;
  /* border-bottom: solid 2px #ebeaeb; */
  border-bottom: solid 2px transparent;
  font-size: 18px;
  transition: border 0.3s, color 0.3s;
  /* padding: 1rem 2rem; */
  padding: 8px;
}

.feed-tabs .nav-item .nav-link.active {
  /* border-bottom: solid 4px #8381ff; */
  border-bottom: solid 2px #6c54bd;
  color: #2b08a0;
}

.feed-tabs .nav-item .nav-link:hover {
  color: #2b08a0;
}

.card.gesture-card {
  cursor: pointer;
  border: none;
  padding: 16px 16px 0px;
}

.card.gesture-card .card-header {
  background-color: transparent;
  border: none;
}

.feed-post-header .post-fromto-info {
  /* text-overflow: ellipsis;
  white-space: nowrap;
  width: min-content;
  overflow: hidden; */
  width: calc(100% - 40px);
}

.card.gesture-card.feed-post .feed-post-message {
  font-size: 15px;
  font-weight: 400;
  line-height: 17.89px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #8f8e90;
}

.card.gesture-card.feed-post .feed-post-message::before {
  content: "\201C";
  font-size: 15px;
}

.card.gesture-card.feed-post .feed-post-message::after {
  content: "\201D";
  font-size: 15px;
}

.feed-post-body.card-body,
.feed-post-footer.card-footer {
  padding: 0;
}

/* Card-body main-image */
.feed-post-main-image {
  width: 100%;
  height: 192px;
  object-fit: cover;
  object-position: top center;
  border-radius: 24px;
}

.feed-post-footer.card-footer {
  height: 24px;
  padding: 0px 0px;
  background-color: transparent;
  border-top: none;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.125); */
}

.card-header {
  padding: 0px 0px !important;
}

.spacer {
  flex: 1 1 100%;
}

.feed-post-footer .post-timestamp {
  flex: 1 0 auto;
}

.feed-post-body .feed-post-main-image-container {
  position: relative;
}

.feed-post-body .feed-post-main-image-container .expand-button:hover {
  transform: scale(1.2);
}

.feed-post-body .feed-post-main-image-container .expand-button {
  position: absolute;
  top: 8px;
  right: 8px;
  transition: all ease-in-out 0.3s;
}

.feed-post-body .feed-post-main-image {
  width: 100%;
  height: 192px;
  object-fit: cover;
  object-position: top center;
  border-radius: 24px;
}

/* */
@media (max-width: 600px) {
  /* .navbar-feed-screen.new-navbar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 6.5vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px;
    display: none;
  }

  .navbar-feed-screen.new-navbar img {
    width: 30px;
  } */

  .feedsscreen-container > div,
  .feedsscreen-container header {
    margin: 0 auto;
    width: 100%;
    max-width: 100vw;
  }

  .feed-post-footer .post-fromto,
  .feed-post-footer .post-chatroom-btn,
  .feed-post-footer i.far.fa-comment,
  .feed-post-footer i.far.fa-comment {
    color: var(--primary-color);
  }

  .main-header.feed-screen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 10vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    /* box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px; */
  }

  .feed-post-header .post-fromto {
    color: var(--primary-color);
  }

  /* Cards */

  .card {
    /* margin-bottom: 16px; */
    /* box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px; */
  }

  .feeds-loading {
    display: flex;
    justify-content: center;
    margin-top: 50vh;
  }

  .more-feeds-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }

  .edit-button:focus,
  .edit-button:active {
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }

  /* Card-Header on mobile */
  .feed-post-header {
    padding: 8px;
  }

  /* Card-Body main-image on mobile */
  .feed-post-body .feed-post-main-image {
    width: 100%;
  }

  /* Card body message and doodle on mobile */
  .message-doodles {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    margin: 4px auto;
  }

  .message-doodle-showMore {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    margin: 16px auto;
  }

  .post-message.text-center {
    padding-left: 16px;
    padding-right: 16px;
  }

  .post-message.text-center i.fas.fa-quote-left,
  .post-message.text-center i.fas.fa-quote-right {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .post-message.text-center i.fas.fa-quote-right {
    margin-top: 2%;
  }

  .message-doodles .post-message {
    width: 60%;
  }

  .message-doodles .post-sub-image {
    width: 40%;
  }

  .message-doodle-showMore .post-sub-image {
    width: 40%;
    margin-top: 16px;
  }

  .message-doodles .post-sub-image .feed-post-sub-image {
    width: 60%;
  }

  .message-doodle-showMore .post-sub-image .feed-post-sub-image {
    width: 100%;
  }

  div.post-sub-image.no-main-image img {
    width: 100%;
  }

  .post-message i,
  .post-message p {
    display: block;
    margin: 0;
    font-size: 0.8rem;
  }

  /* Card-Footer on mobile */
  .feed-post-footer {
    display: flex;
    flex-direction: column;
  }

  .feed-post-footer .post-chatroom-btn {
  }

  .post-chatroom-button {
    color: var(--primary-color);
  }

  .post-chatroom-btn i {
    font-size: 1.5rem;
    position: absolute;
    right: 22px;
    bottom: 6%;
  }

  .feed-post-footer .fas.fa-quote-left,
  .feed-post-footer .fas.fa-quote-right {
    font-size: 0.5rem;
  }

  .feed-post-footer small {
    display: block;
    color: #a6a9b6;
    text-align: left;
  }

  .feed-post-footer {
    height: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  /* Load more button on mobile */
  .load-more-btn.load-more-feeds {
    background-color: var(--primary-color);
    display: block;
    width: 124px;
    border: 0;
    color: #fff;
    margin: 4% auto;
    border-radius: 23px;
    padding: 1%;
  }

  .load-more-btn.load-more-feeds:active,
  .load-more-btn.load-more-feeds:focus {
    outline: 0;
    border: 0;
  }

  /* Split line on mobile */
  .split-line {
    border-bottom: 1px dotted #a6a9b6;
    padding: 2%;
    margin: 2% auto;
    width: 90%;
  }

  .edit-button {
    color: var(--comp-color);
    font-size: 1.2rem;
  }
}

/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/

/* Media query */
/* Width wider than mobile device */
@media (min-width: 601px) {
  /* Navbar */
  /* .navbar-feed-screen.new-navbar {
    display: none;
  } */

  .feed-post-header .post-fromto {
    color: var(--primary-color);
  }

  .feed-post-header .post-fromto-info {
    /* margin: 0 auto; */
  }

  .edit-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }

  .edit-button:focus,
  .edit-button:active {
    border: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
  }

  /* Card */

  .card-body {
    padding: 0.75rem 1.25rem;
  }

  .feeds-loading {
    display: flex;
    justify-content: center;
    margin-top: 35vh;
  }

  .more-feeds-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .card {
    width: 100%;
    border: 1px solid #ddd;
    margin: 0 auto 0 auto;

    /* box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px; */
  }

  .border-rounded {
    border-radius: 24px;
  }

  /* Card-header */
  .feed-post-header {
    padding: 4px;
  }

  p.post-fromto-info.my-1.text-center {
    font-size: 1.5rem;
    padding: 2%;
  }

  /* Card-body message and doodle */
  div.message-doodles {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 16px;
    height: 58px;
  }

  div.message-doodle-showMore {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-direction: column;
    margin: 2%;
  }

  div.message-doodles div.post-message.text-center {
    width: 60%;
  }

  div.message-doodles div.post-sub-image {
    width: 40%;
    padding: 2%;
    padding-right: 16px;
  }

  div.message-doodle-showMore div.post-sub-image {
    width: 40%;
    margin-top: 16px;
  }

  div.message-doodles div.post-sub-image img {
    width: 100%;
  }

  div.message-doodle-showMore div.post-sub-image img {
    width: 100%;
  }

  div.post-sub-image .feed-post-sub-doodle {
    width: 100%;
  }

  div.post-message.text-center {
    word-wrap: break-word;
    padding: 2%;
  }

  div.post-message.text-center p {
    font-size: 1.2rem;
  }

  /* Card-footer */
  .feed-post-footer {
    display: flex;
    flex-direction: column;
    padding: 16px;
    height: 8vh;
  }

  .post-chatroom-btn {
    position: relative;
    text-align: left;
  }

  .post-chatroom-button {
    color: var(--primary-color);
  }

  .post-chatroom-btn i {
    font-size: 2rem;
    position: absolute;
    right: 22px;
    bottom: 0;
  }

  .feed-post-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .feed-post-footer .post-chatroom-btn .post-chatroom-button:hover {
    color: var(--secondary-color);
  }

  /* Card-Footer on desktop */
  .feed-post-footer {
    display: flex;
    flex-direction: column;
  }

  .feed-post-footer .post-chatroom-btn {
  }

  .post-chatroom-button {
    color: var(--primary-color);
  }

  .post-chatroom-btn i {
    font-size: 1.5rem;
    position: absolute;
    right: 22px;
    bottom: 10%;
  }

  .feed-post-footer .fas.fa-quote-left,
  .feed-post-footer .fas.fa-quote-right {
    font-size: 0.5rem;
  }

  .feed-post-footer small {
    display: block;
    color: #a6a9b6;
    text-align: left;
  }

  .feed-post-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .feed-post-footer .post-timestamp {
  }

  /* Load more button */
  .load-more-btn.load-more-feeds {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
    padding: 0.5%;
    width: 124px;
    max-width: 300px;
    border-radius: 23px;
    outline: 0;
    border: 0;
    background-color: var(--primary-color);
    color: #fff;
  }

  .load-more-btn.load-more-feeds:active,
  .load-more-btn.load-more-feeds:focus {
    outline: 0;
    border: 0;
  }

  /* Split line */
  .split-line {
    border-bottom: 1px dotted #a6a9b6;
    padding: 2%;
    margin: 2% auto;
    width: 90%;
  }

  .edit-button {
    color: var(--comp-color);
    font-size: 1.2rem;
  }
}
