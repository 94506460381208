.container {
    display: flex;
    justify-content:center;
    width:100%;
    gap: 10px;
}
.dropDownStyle {
    border-radius: 32px;
    background-color: #fff;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
    font-family: "Mulish";
    padding: 5px;
    display: flex;
    flex-direction:column;
    gap: 5px;
    position: relative;
    top:20;
    width:fit-content;
    height: 40px;
    cursor: pointer;
    padding: 0 12;
}

.checkboxStyle {
    border-radius: 32px;
    background-color: #fff;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
    font-family: "Mulish";
    padding: 5px;
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 5px;
    position: relative;
    top:20;
    width:fit-content;
    height: 40px;
    padding: 0 12;
    cursor: pointer;
}
.crmRedirect {
    border-radius: 32px;
    background-color: #fff;
    box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
    font-family: "Mulish";
    padding: 5px;
    display: flex;
    align-items:center;
    justify-content: center;
    gap: 5px;
    position: relative;
    top:20;
    width:fit-content;
    min-width: 100px;
    height: 40px;
    padding: 0 12;
    cursor: pointer;
    color: #000;
    font-size: 17px;
    font-weight: 600;
    text-decoration: none;

}

.innerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width:100%;
    height:100%;
    gap: 5px;
    font-weight:600;
}


.noselect {
    -webkit-touch-callout: none; 
      -webkit-user-select: none;
       -khtml-user-select: none; 
         -moz-user-select: none; 
          -ms-user-select: none; 
              user-select: none; 
  }

.mainTitle {
    font-size: 17px;
}

.buttonStyles {
    background-color: white;
    border-radius: 100%;
    height:fit-content;
    width:fit-content;
    transform:rotate(0deg);

}

.buttonStylesOpen {
    background-color: white;
    border-radius: 100%;
    height:fit-content;
    width:fit-content;
    transform:rotate(-180deg);
}


.checkboxContainer {
    position:absolute;
    top:50;
    left: 0px;
    height: fit-content;
    width: fit-content;
    background-color: #fff;
    border-radius: 8px;
}

.innerCheckContainer {
    margin: 0 5 -5 10;
    cursor: pointer;
    font-weight:600;
}


.pickupContainer{
    display:flex;
    height:100%;
    width:100%;
}

.runnerContainer {
    padding: 12 12 5 12;
    border-radius: 8px 8px 0 0;
    background-color:#8585F7;
}

.productsContainer {
    color:black;
    font-weight: 700;
    border-radius: 0 0 8px 8px;
    padding:  5 12 5 12;
}

.imageContainer{
    display:flex;
    gap: 2px;
    position:absolute;
    top:-25;
}

.closeButton {
    position:absolute;
    right:-2;
    top:-2;
    height:fit-content;
    width:fit-content;
    border-radius: 100%;
}

.markerShadow {
    box-shadow: 0px 0px 9px white, 0px 0px 9px white;
    border-radius: 100%;
    height:35px;
    width:35px;
    position:absolute;
    top:-35;
    left:-18;
  }

/* .closeButton:focus {
    outline:none;
}

.closeButton:hover {
    outline:none;
    background-color:none;
} */

.name {
    font-weight: 700;
}

.infoContainer {
    height:100%;
    width:100%;
    cursor: pointer;
}

.infoCard {
    display:flex;
    align-items:center;
    height:100%;
    width:100%;
    color: black;
    font-family: 'Mulish';
    font-weight: 700;
    padding:5px;
}