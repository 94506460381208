.refresh_button {
  background-color: #47c5f6;
  color: #ffffff;
  border: none;
  border-radius: 15px;
  font: 18px 'Mulish', sans-serif;
  padding: 6px 6px 6px 6px;
  margin-right: 5%;
}
.bar {
  width: 100%;
  display: flex;
  color: #47c5f6;
  justify-content: flex-end;
  align-items: center;
}
.refreshbar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  flex-basis: 1;
  width: 100%;
  height: 6%;
  margin: 0% 0% 0% 0%;
  background-color: #47c5f6;
  position:sticky;
}
@media (max-width: 800px) {
  .refresh_button {
    background-color: #47c5f6;
    color: #ffffff;
    border: none;
    border-radius: 15px;
    font: 16px 'Mulish', sans-serif;
    padding: 6px 6px 6px 6px;
    margin-right: 5%;
  }
  .bar {
    width: 100%;
    display: flex;
    color: #47c5f6;
    justify-content: flex-end;
    align-items: center;
  }
  .refreshbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: 1;
    width: 100%;
    height: 6%;
    margin: 0% 0% 0% 0%;
    background-color: #47c5f6;
  }
}

.refreshLogo{
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin: 5px 0;
}