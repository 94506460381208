.heading {
  flex: 0.1;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgb(134, 137, 253);
  position: sticky;
  z-index: 6;
  top: 0;
}

.headingimg {
  /* Ellipse 91 */
  flex: 0.2;
}

.heading_space {
  flex: 0.2;
}

.heading_text {
  /* Gesture Orders */
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  /* identical to box height, or 24px */
  flex: 0.8;
  color: #fff;
}

.back_btn {
  height: 35px;
  width: 35px;
  margin: 10px;
}
