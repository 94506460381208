.Vendor-order-container{
    padding-top:5px;
    padding-bottom: 5px;
    margin-bottom:1em;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: 1px solid #FFFCFC;
    
    display: flex;
    flex-direction: row;
    background-color: #F4F4F4;
    align-items: center;
    width: 100%;
    cursor: pointer;
}
.Vendor-order{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}