.modal {
  position: absolute;
  z-index: 5;
  width: clamp(50%, 700px, 90%);
  max-height: min(75vh, 700px);
  margin: auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgb(241, 241, 241);
  overflow: auto;
}

.close-button {
  z-index: 6;
  position: sticky;
  top: 0;
}

.text-container {
  padding: 1rem;
}

.text {
  font-size: 1rem;
  font-family: 'Mulish';
  margin: 0;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}
