.message-background {
  background-color: rgb(251, 251, 251);
}

.message-loading {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.save-loading {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
}

.design-edit-card-opener {
  min-width: 280px;
  display: flex;
  justify-content: center;
  max-width: 484px;
  padding: 20px;
  margin: 0 auto;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.change-button {
  background-color: #2b08a0;
  font-family: 'Mulish';
  position: absolute;
  color: white;
  top: 5px;
  right: 5px;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: clamp(8px, 4vw, 12px);
  box-shadow: 0px 5px 10px rgb(0 0 0 / 35%);
  border: none;
}

.change-button:focus {
  outline: none;
}

.static-word {
  font-size: 1.2em;
  align-self: flex-start;
}

.preview-container {
  position: relative;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
}

.preview-image-line {
  height: 40px;
  width: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 10px;
}