/* Global */
/* @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@400;700&display=swap'); */

:root {
  --primary-color: #2b08a0;
  --secondary-color: #ffb538;
  --comp-color: #ffc285;
  --background-color: #f4f4f4;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0 !important;
  background-color: #fff;
  font-family: "Alte Haas Grotesk", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
}

body.modal-open {
  padding: 0 !important;
}

.main-comments-sender-info {
  flex: 1;
}

.main-comments-sender-info p {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}

.username-and-screenname {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.comments-input-container {
  margin: 16px;
  position: sticky;
  bottom: 16px;
}

.comments-input-container > div {
  box-shadow: 0px 20px 20px 0px #0000001a;
  border-radius: 20px;
  border: 1px solid #e8e8ff;
  background-color: #fff;
}

.comments-input-container .comment-send-form {
  border: none;
  background-color: #f8f8f9;
  display: flex;
  justify-content: space-between;
}

.comments-input-container input::placeholder {
  color: #a1a0a2;
  font-size: 18px;
}

.comment-form .comment-send-form input.comment-form-input {
  flex: 1;
  height: 35px;
  border: 0;
  padding: 16px;
}

.comment-form .comment-send-form .msg-send-button {
  border: 0;
  color: #fff;
  background-color: transparent;
  flex: 0 1 max-content;
  cursor: pointer;
}

.comment-form .comment-send-form .msg-send-button:disabled {
  cursor: not-allowed;
}

.comment-form .comment-send-form .msg-send-button:disabled svg rect {
  transition: all ease-in-out 0.3s;
}

.comment-form .comment-send-form .msg-send-button:disabled svg rect {
  fill: #ebeaeb !important;
}

.comment-form .comment-send-form .msg-send-button svg rect {
  fill: #2b08a0 !important;
}

@media (max-width: 600px) {
  /* Contents on mobile */
  section.main-comments {
    height: 100%;
    overflow: hidden;
  }

  .main-comments-top {
    position: sticky;
    overflow: hidden;
    z-index: 100;
    width: 100%;
    bottom: 0;
  }

  /* Go back button area on mobile */
  .go-back-btn .go-back {
    color: var(--primary-color);
  }

  .main-comments-go-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 16px;
    position: relative;
    background: #fff;
  }

  .main-comments-go-back .go-back {
    padding: 0;
  }

  p.post-fromto-info.my-1.comments-sender-info {
    padding: 0;
    /* margin-right: 98px; */
    font-weight: 700;
    font-size: 28px;
  }

  /* Text input area on mobile */
  .comment-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .comment-send-form {
    border-radius: 30px;
    background-color: #fff;
    width: 100%;
    padding-left: 5px;
    border: 1px solid #dcdcdc;
    margin-bottom: 0;
  }

  .comment-form .comment-send-form input:focus {
    outline: 0;
  }

  .comment-form .comment-send-form .msg-send-button:active,
  .comment-form .comment-send-form .msg-send-button:focus {
    outline: 0;
    border: 0;
    background-color: transparent;
  }

  .comment-form .comment-send-form .msg-send-button span:active,
  .comment-form .comment-send-form .msg-send-button span:focus {
    outline: 0;
    border: 0;
    background-color: #fff;
  }

  .msg-send-button span {
    color: var(--primary-color);
    font-size: 1.05rem;
  }

  /* Message area on mobile */
  .comments {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
  }

  .screenname {
    margin: 0;
  }

  .messages-loading {
    display: flex;
    justify-content: center;
    margin-top: 40vh;
  }

  .more-messages-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comments-scrollable-area {
    margin-top: 16px;
    height: calc(100vh - 30vh);
    min-height: max-content;
    overflow: auto;
    overflow-x: hidden;
  }

  .comments-area {
    overflow: hidden;
    overflow-x: hidden;
  }

  .comments-area .com-each-comment .text-comment-container {
    width: 100%;
    word-break: break-word;
  }

  .comments-area .user-and-comment {
    display: flex;
    flex-direction: column;
  }

  .com-each-comment {
    display: flex;
    flex-direction: row;
  }

  .comment-profile-image {
  }

  .screenName-and-message {
    padding-left: 16px;
    flex: 1;
  }

  .comments-area .com-each-comment .username-display {
    margin-bottom: 1px;
  }

  .comments-area .com-each-comment .username-display span {
    font-weight: bold;
  }

  .com-each-comment .comment-timestamp {
    color: #aaa;
  }

  .comments-area .com-each-comment {
    border-bottom: 1px solid rgb(239, 243, 244);
    padding: 16px;
    display: flex;
  }

  .comments-area .com-each-comment p.com-text-comment,
  .comments-area .com-each-comment p.com-text-comment {
    margin: 0;
    width: 100%;
    max-width: 100%;
  }

  /* Load more button on mobile */
  .comments-load-more {
    display: flex;
    justify-content: center;
    margin: 5%;
  }

  .load-more-button {
    outline: 0;
    border: 0;
    padding: 16px 5%;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 23px;
  }

  .load-more-button:focus {
    outline: 0;
    border: 0;
  }
}
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/
/**/

/* Media query for tablet and desktop view */
@media (min-width: 601px) {
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  /* contents */
  section.main-comments {
    max-width: 500px;
    /* height: 100%; */
    max-height: max-content;
    margin: 0 auto;
    /* margin-top: 20%; */
    /* box-shadow: rgb(30 61 99 / 50%) 0px 2px 10px 0px; */
  }

  /* Go back button area */
  .go-back-btn .go-back {
    padding: 16px;
    padding-left: 5%;
    color: var(--primary-color);
  }

  .main-comments-go-back {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    position: relative;
  }

  .main-comments-go-back .go-back {
    padding: 0;
  }

  p.post-fromto-info.my-1.comments-sender-info {
    padding: 0;
    /* margin-right: 140px; */
    font-weight: 700;
    font-size: 28px;
  }

  /* text input area */
  .comment-form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }

  .comment-send-form {
    border-radius: 30px;
    background-color: #fff;
    width: 80%;
    padding-left: 5px;
    border: 1px solid #dcdcdc;
    margin-bottom: 0;
  }

  .comment-form .comment-send-form input.comment-form-input {
    width: 75%;
    height: 35px;
    margin: 6px 1% 6px 1%;
    border: 0;
    padding: 16px;
  }

  .comment-form .comment-send-form input:focus {
    outline: 0;
  }

  .comment-form .comment-send-form .msg-send-button {
    border: 0;
    color: #fff;
    background: transparent;
    display: inline;
    padding: 0;
    position: relative;
    margin-left: 22px;
    margin-bottom: 5px;
  }

  .comment-form .comment-send-form .msg-send-button:focus {
    outline: 0;
    border: 0;
    background-color: transparent;
  }

  .comment-form .comment-send-form .msg-send-button span:focus {
    outline: 0;
    border: 0;
    background-color: transparent;
  }

  .msg-send-button span {
    color: var(--primary-color);
    font-size: 1.05rem;
  }

  /* message area */
  .comments {
    margin: 0 auto;
    width: 100%;
    /* height: 60vh; */
    /* overflow: scroll;
    overflow-x: hidden; */
    /* overflow-y: auto; */
  }

  .comments-area {
    overflow-x: hidden;
    overflow: hidden;
  }

  .comments-area .com-each-comment p.com-text-comment,
  .comments-area .com-each-comment p.com-text-comment {
    width: 100%;
    max-width: 100%;
  }

  .messages-loading {
    display: flex;
    justify-content: center;
    /* margin-top: 25vh; */
  }

  .more-messages-loading {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .comments-area .com-each-comment {
    margin: 16px 0 16px 0;
    border-bottom: 1px solid rgb(239, 243, 244);
    padding: 16px;
    display: flex;
  }

  .comments-area .com-each-comment p,
  .comments-area .com-each-comment p {
    margin: 0;
  }

  .comments-area .com-each-comment .username-display span {
    font-weight: bold;
    word-wrap: normal;
  }

  .comments-area .com-each-comment .text-comment-container {
    width: 100%;
    word-break: break-word;
  }

  .comments-area .user-and-comment {
    display: flex;
    flex-direction: column;
  }

  .comment-profile-image {
  }

  .screenName-and-message {
    padding-left: 16px;
    flex: 1;
  }

  .com-each-comment .comment-timestamp {
    color: #aaa;
  }

  /* Load more button */
  .comments-load-more {
    display: flex;
    justify-content: center;
    margin: 5%;
  }

  .load-more-button {
    outline: 0;
    border: 0;
    padding: 16px 5%;
    color: #fff;
    background-color: var(--primary-color);
    border-radius: 23px;
  }

  .load-more-button:focus {
    outline: 0;
    border: 0;
  }
}
