.btnContainer {
	position: sticky;
	bottom: 0;
	background-color: white;
	padding: 10px;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	z-index: 10;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.viewBtn{
	background-color: #000;
	color: #fff;
	width: 100px;
	height: 45px;
	border-radius: 5px;
	outline: none;

}