.delete-account-container {
    padding:10px;
	display: flex;
	background-color: #ffffff;
	/* padding-left: 3em;
	padding-right: 3em; */
	flex-direction: column;
	height: 90%;
	width: 100%;
	/* height: 100%; */
    align-items: center;
}
.delete-account-notice-bot-text-container{
    background-color:#eee;
}
.loading {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}
.delete-account-text{
    display: flex;
    flex-direction: column;
    max-width:500px;
    width: 100%;
    align-items:center;
    
    height:100%;
}
.delete-account-notice-top-text{
    padding-left: 20px;
    padding-right: 20px;
    font-weight: bold;
    font-size: 1.2em;
    font-family: "mulish";
    margin-top:10px;
    margin-bottom: 10px;
}
.delete-account-notice-mid-text{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1em;
    font-family: "mulish";
    margin-top:10px;
    width: 100%;

}
ul{
    list-style-type: circle;
    padding-left: 20px;
}
li{
    margin-top: 4px;
}
.delete-account-notice-bot-text-container{
    margin-top:auto;
    margin-left: 20px;
    margin-right: 20px;
}
.delete-account-notice-bot-text{
    font-size: 1em;
    font-weight: bold;
    font-family: "mulish";
    
}
.delete-account-continue-div{
    display: flex;
    width: 100%;
    align-items: center;

}

.delete-account-continue-div-button {
        z-index: 1;
        bottom: 2rem;
        left: 0;
        right: 0;
        margin: 0 auto;
        color: white;
        background-color: #2b08a0;
        border: none;
        border-radius: 5px;
        font-family: "Mulish";
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
        font-size: 20px;
        width: 90%;
        height: 55px;
        max-width: 550px;
        margin-top: 20px;
        margin-bottom: 20px;
    
}

.delete-account-continue-div-button-text {
	font-size: 1.25em;
    font-family: "Mulish";
	color: #ffffff;
}

