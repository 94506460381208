.edit-card {
    position: absolute;
    z-index: 50;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: min(100%, 500px);
    height: 100%;
    background-color: white;
    box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.35);
  }
  
  .header-container {
    padding: 10px;
  }
  
  .header{
    height: 55px;
  }  

  .close-button {
    z-index: 60;
    position: absolute;
    top: 10px;
    left: 5%;
  }
  
  .header-text {
    position: absolute;
    top: 12px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
    font-size: 20px;
    font-family: 'Mulish';
    font-weight: bold;
  }

  .search-bar-container{
    position: absolute;
    /* border: 2px solid black; */
    border: none;
    left: 5%;
    height: 50px;
    width: 90%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin-top: 10px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
  }
  
  input:focus, textarea:focus {
    outline: none;
}

  .content-container {
    z-index:100;
    margin-top: 25px;
    height: calc(100vh - 115px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  .search-bar-form{
    width: 100%;
    height: 50px;
    padding: 10px;
  }

  input[type=search]::-ms-clear { display: none; width : 0; height: 0; }
  input[type=search]::-ms-reveal { display: none; width : 0; height: 0; }
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration { display: none; }

  .search-bar{
    z-index: 60;
    border: none;
    margin-top: 5px;
    height: 40px;
    font-size: 16px;
    width: 75%;
    font-family: 'Mulish';
  }

  .icon{
    z-index: 100;
    right:0px;
    min-height: 50px;
    min-width: 50px;
    position: absolute;
    color: white;
    background-color: #2b08a0;
    padding: 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .clear{
    z-index: 100;
    right: 45px;
    min-height: 45px;
    min-width: 45px;
    position: absolute;
    color: rgba(85, 85, 85, 0.767);
    padding: 10px;
  }

  .image-container{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .image{
    width: 100%;
  }

  .image-txt{
    width: 95%;
    margin-top: 5px;
    text-align: right;
    font-family: 'Mulish';
    font-size: 16px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .h-divider {
    width: 100%;
    position: relative;
  }
  
  .h-divider .shadow {
    overflow: hidden;
    height: 20px;
  }

  .h-divider .shadow:after {
    content: '';
    display: block;
    margin: -25px auto 0;
    width: 100%;
    height: 25px;
    border-radius: 125px/12px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35);
  }

  .no-results-txt{
    font-family: 'Mulish';
    font-size: 16px;
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  .link {
    color: blue;
    text-decoration: underline; /* no underline */
  }

  @media screen and (max-width: 300px) {
    .image-txt{
        font-size: 12px;
      }
  }

  @media screen and (max-width: 800px) {
    .search-bar-container{
        border: 1px solid black;
        border: none;
      }
  }