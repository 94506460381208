.main-container {
	display: flex;
	background-color: #ffffff;
	
	flex-direction: column;
	max-width: 550px;
	width: 100%;
	height: 100%;
}
.spinner{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.next-button {
	z-index: 1;
	bottom: 2rem;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
	max-width: 500px;
	margin-top: 4vh;
	margin-bottom: 3vh;
}
.tasks-container{
	display: flex;
	flex-direction: column;
	margin-top: 7vh;
}
.tasks-text-container{
    margin-top:3vh;
    width: 90%;
    max-width:90%;
    background-color: #eee;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.tasks-text-title{
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    text-align: left;
    margin-bottom: 1vh;
	font-weight: bold;
}
.tasks-text{
    font-family: "Mulish";
    font-size: 18px;
    margin: 0;
	padding-bottom: 0.5vh;
	}
