.modal {
  position: absolute;
  z-index: 5;
  width: clamp(50%, 700px, 90%);
  max-height: min(75vh, 700px);
  margin: auto;
  padding: 1rem;
  border-radius: 12px;
  background-color: rgb(241, 241, 241);
  overflow: auto;
}

.brand-logo {
  width: 50%;
  margin: 1rem auto;
  display: block;
}

.heading {
  font-size: 1.2rem;
  font-family: 'Mulish';
  font-weight: bold;
  margin: 0;
  text-align: center;
}

.text-container {
  padding: 1rem;
  text-align: center;
}

.text {
  font-size: 1rem;
  font-family: 'Mulish';
  margin: 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.okay-button {
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  padding: 10px;
  width: 100%;
}
