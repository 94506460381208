.main-container{
    display:flex;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding-left: 3em;
    padding-right: 3em;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}