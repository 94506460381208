.screen-loading {
	z-index: 100;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform: -webkit-translate(-50%, -50%);
	transform: -moz-translate(-50%, -50%);
	transform: -ms-translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

.content-container {
	display: flex;
	background-color: #ffffff;
	width: 100% !important;
	height: 95%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 1200px !important;
}

.infoContainer {
	width: 50%;
	border: 2px solid #eee;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: #eee;
	font-family: "Mulish";
	border-radius: 5px;
	font-weight: 700;
	max-width: 500px !important;
	text-align: center;
	font-size: 14px;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 10px;
	flex-direction: column;
	position: absolute;
	right: 10;
	background-color: white;
	padding: 5;
}

.closeIcon {
	margin-left: 15;
	font-size: 14px;
	top: 0;
}

.cookiePermission {
	color: blue;
	text-decoration: underline;
	font-size: 15px;
	/* width: 90%; */
	margin-top: 10px;
	margin-bottom: 10px;
	font-family: "Mulish";
	font-weight: 700;
	/* max-width: 1200px !important; */
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	position: absolute;
	right: 10;
	background-color: white;
	padding: 5;
	border-radius: 5px;
	opacity: 60%;
}

.iframeContainer {
	width: 100%;
	height: 100%;
}
@media (max-height: 926px) {
	.content-container {
		height: 95%;
	}
	/* .iframeContainer {
		--webkit-overflow-scrolling: touch;

		overflow-y: scroll;
	} */
}

.permissionContainer{
	max-width: 1200px !important;
	position: fixed;
	width: 100%;
}