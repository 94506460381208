.top-bar{
    padding-top:10px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
}

.top-bar-bar{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.title{
    font-size: 1.6em;
    font-weight: bold;
    
}
.orderhistory-divider{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.main-container{
    background-color: #ffffff;
    height: 100%;
    max-width: 1200px;
    width: 100%;
}
.vendor-details-body{
    display: flex;
    width: 100%;
    
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.order-details-container{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
    
}
.order-details-box{
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #FFFCFC;
    margin-bottom: 2em;
    margin-left: 15px;
    margin-right: 15px;
    justify-content:space-between;
    box-shadow: 10px 5px 10px 10px #0000002d  ;
}
.order-details-box-left{
    display: flex;
    flex-direction: column;
    background-color: #f4f4f4;
    border-radius: 10px;
    border: 1px solid #FFFCFC;
    margin-bottom: 0.8em;
    margin-left: 15px;
    margin-right: 1em;
    justify-content:space-between;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left:10px;
    padding-right: 10px;
    flex:0.6;
    
}
.spinner{
    display: flex;
    justify-content:center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.top-text-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 40px;
    
}
.container{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    }
.orderhistory-divider{
    margin-bottom: 3%;
}
.title-left{
    font-size: 1.2em;
    font-weight: bold;
}
.expense-info-div{
 display: flex;
 flex-direction: row;
 justify-content: space-between;
 padding-left: 1%;
 padding-right: 2%;
}
.title-expenses{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
}
.order-details-box-right{
    display: flex;
    flex-direction: column;
    background-color: #F4f4f4;
    border-radius: 10px;
    border: 1px solid #FFFCFC;
    margin-bottom: 2em;
    
    margin-right: 15px;
    justify-content:space-between;
    padding-top: 2%;
    padding-bottom: 2%;
    padding-left:2%;
    padding-right: 2%;
    flex:0.4;
}
