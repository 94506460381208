.mainContainer {
	padding: 10px;
	display: flex;
	background-color: #ffffff;
	/* padding-left: 3em;
	padding-right: 3em; */
	flex-direction: column;
	height: 90%;
	width: 100%;
	/* height: 100%; */
	align-items: center;
}

.container {
	display: flex;
	flex-direction: column;
	max-width: 500px;
	width: 100%;
	align-items: center;
	height: 100%;
	justify-content: space-between;
}

.mainTitle {
	padding-left: 20px;
	padding-right: 20px;
	font-weight: bold;
	font-size: 1.2em;
	font-family: "Mulish";
	margin-top: 10px;
	margin-bottom: 10px;
	width: 100%;
}

.checkList {
	padding-left: 20px;
	padding-right: 20px;
	font-size: 1em;
	font-family: "Mulish";
	width: 100%;
}

.checkListTitle {
	font-size: 1.1em;
	font-family: "Mulish";
	font-weight: bold;
}

/* checkbox styling */

.checkboxContainer {
	display: flex;
	align-items: flex-start;
	padding-left: 20px;
	padding-right: 20px;
	width: 100%;
	cursor: pointer;
	margin-top: 30px;
}

.checkbox {
	height: 20px;
	width: 20px;
	margin-right: 5px;
	cursor: pointer;
}
.checkboxText {
	line-height: 1.1;
	font-weight: bolder;
}

/* note */
.noteContainer{
	background-color: #eee;
	margin-top: 30px;
	margin-left: 20px;
	margin-right: 20px;
}
.noteText {
	font-weight: bold;
	font-family: "Mulish";
	padding: 5px;
}

/* btn container */

.btnsContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.btnMainContainer {
	display: flex;
	width: 100%;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 10px;

	padding-left: 20px;
	padding-right: 20px;
}

.btnContainer {
	z-index: 1;
	bottom: 2rem;
	left: 0;
	right: 0;
	/* margin: 0 auto; */
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 100%;
	height: 55px;
	max-width: 550px;
}

.btnText {
	font-size: 1em;
	font-family: "Mulish";
	color: #ffffff;
}
