.footer-container {
  position: relative;
  z-index: 1;
  max-width: 444px;
  margin: 30px auto;
  padding: 0 20px;
}

.app-store-text p {
  text-align: center;
  font-size: 1rem;
  font-family: 'Mulish';
  color: rgb(111, 111, 111);
}

.app-store-images-row {
  display: flex;
  gap: 20px;
  width: min(100%, 320px);
  margin: 0 auto;
}

.app-store-images-row img {
  width: 100%;
}

.coupon-text {
  margin-top: 20px;
}

.coupon-text p {
  text-align: center;
  font-size: 1rem;
  font-family: 'Mulish';
  color: rgb(111, 111, 111);
  margin: 0;
}
