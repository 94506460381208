.verify-email-container {
  padding: 1rem;
  max-width: 425px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.send-email-loading {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.verify-code-loading {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform: -webkit-translate(-50%, -50%);
  transform: -moz-translate(-50%, -50%);
  transform: -ms-translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.25);
}

.verify-text {
  font-size: 1rem;
  text-align: center;
  font-family: 'Mulish';
}

.code-input {
  font-size: 2rem;
  border-radius: 0;
  border-top: 0;
  border-bottom: 3px solid #2b08a0;
  border-left: 0;
  border-right: 0;
  padding: 10px 0;
  text-align: center;
  width: 100%;
  background-color: white;
}

.verify-button {
  color: white;
  display: block;
  margin: 20px auto 0 auto;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: clamp(12px, 5vw, 20px);
  width: 100%;
  height: 55px;
}

.resend-button {
  margin: 20px auto;
  font-family: 'Mulish';
  font-size: 1.2rem;
  color: #2b08a0;
  border: none;
  text-decoration: underline;
}

.code-sent-text {
  margin: 20px auto;
  font-family: 'Mulish';
  font-size: 1.2rem;
}
