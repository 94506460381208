.edit-card {
  position: fixed;
  display: none;
  z-index: 100;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  width: min(100%, 500px);
  height: 100vh;
  background-color: transparent;
}

.content-box{
  z-index: 120;
  top: 30%;
  left: min(5%, 250px);
  position: absolute;
  width: min(90%, 500px);
  height: max-content;
  padding: 20px;
  background-color: #F0F2FF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.text-box{
  color: #8C8C8C;
  font-family: 'SF Pro Text';
  font-size: 16px;
  text-align: center;
  letter-spacing: 0.02em;
  padding:5px;
}

.close{
  margin: 0 auto;
  color: white;
  border: none;
  height: 30px;
  position: absolute;
  right: 0;
}

.close-box{
  width: 100%;
  position: relative;
  height: 30px;
}

  @media screen and (max-width: 300px) {
    .text-box{
      font-size: 14px;
      text-align: center;
    }
  }

  