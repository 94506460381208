.mainContainer {
	max-width: 1200px;
	margin: auto;
	font-family: "Mulish";
	width: 100%;
}

.clipLoader {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}

.collapseSection {
	align-items: center;
	background-color: #f4f4f4;
	border-radius: 10px;
	gap: 10px;
	padding: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-bottom: 10px;
	transition: 0.4s all;
}

.mainTitleContainer {
	display: flex;
	align-items: center;
	cursor: pointer;
	background-color: #f4f4f4;
	padding: 4px;
}
.mainTitle {
	font-weight: 800;
}

.mainBodyContainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	justify-content: space-between;
	align-items: flex-start;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* grid-auto-rows: 1fr; */
    grid-column-gap: 25px;
    grid-row-gap: 10px;
	max-height: 500px;
	overflow-y: auto;
	position: relative;
}

.mainBodyContainer > .btnContainer {
	grid-column: span 2; /* Make .btnContainer span two columns */
	background-color: white;
	padding: 10px;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.mainBodyContainer > .noDataContainer {
	grid-column: span 2; /* Make .btnContainer span two columns */
	background-color: white;
	padding: 10px;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.mainBodyContainer > .addNoteBtnContainer {
	grid-column: span 2;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.container {
	width: 100%;
}
.contentContainer {
	display: flex;
	flex-direction: column;
	background-color: #ffff;
	padding-left: 5px;
	padding-top: 5px;
	padding-bottom: 5px;
	padding-right: 5px;
	border-radius: 5px;
	height: 100%;
}

.bodyInfoContainer {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	padding: 5px;
}

.bodyContainer {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-bottom: 10px;
}

.bodyTitle {
	font-weight: 700;
}

.noDataContainer {
	width: 100%;
	display: grid;
	display: grid;
	width: 100%;
	margin-bottom: 10px;
}

.btnContainer {
	position: sticky;
	bottom: 0;
	background-color: white;
	padding: 10px;
	box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
	z-index: 10;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}
.addNoteBtnContainer {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.actionBtn {
	align-items: center;
	background-color: #000;
	border: none;
	border-radius: 6px;
	box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
		0 1px 5px 0 rgba(0, 0, 0, 0.12);
	color: #fff;
	cursor: pointer;
	display: flex;
	font-family: Mulish;
	font-size: 16px;
	height: 40px;
	justify-content: center;
	/* width: 100px; */
	margin-left: 5px;
	margin-right: 5px;
}

.cancelBtn {
	background-color: #fff;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	padding: 10px;
	width: 40%;
	color: #000;
}
.confirmBtn {
	background-color: #000;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	padding: 10px;
	width: 40%;
	color: #fff;
}
.input {
	border: none;
	outline: none;

	background-color: #f4f4f4;
	padding: 10px;
	text-align: left;
	padding-bottom: 0.4em;
	padding-right: 0.4em;
	width: 400px;
	height: 200px;
	resize: none;
}
