.grunner-input-field {
    padding-top: 1em;
}

.loading-wheel {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.main-container {
    display: flex;
    background-color: #ffffff;
    /* padding-left: 3em;
    padding-right: 3em; */
    flex-direction: column;
    /* height: 100%; */
    justify-content: space-evenly;
    /* margin-top: 10%;
    margin-bottom: 10%; */
    max-width: 550px;
	width: 90%;
    flex: 7;
}

.next-button {
    z-index: 1;

    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: white;
    background-color: #2b08a0;
    border: none;
    border-radius: 5px;
    font-family: 'Mulish';
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
    font-size: 20px;
    width: 90%;
    height: 55px;
    display: inline;
    margin-top: 20px;
	margin-bottom: 20px;
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-end; */
}

.loading {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.signup-signin-div {
    margin-top: 5vh;
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.signup-signin-div-button {
    padding: 0;
    border: none;
    background: none;
}

.signup-signin-div-button-text {
    font-size: 1.25em;
    color: blue;
    transition: all 300ms
}

.signup-signin-div-button-text:hover {
    font-size: 1.35em;
    color: blue;
    transition: all 300ms
}

.policyContainer {
	z-index: 1;
	margin: 0 auto;
	font-family: "Mulish";
	font-size: 15px;
	width: 90%;
	max-width: 500px;
}

.link {
	color: #2b08a0;
}

.link:hover,
.link:focus,
.link:active {
	color: #2b08a0;
	text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 360px) {
	.link {
		font-size: 15px;
		height: auto;
	}
}
