h2 {
	font-size: 1em;
}

.list-container {
	padding-left: 1em;
}

.next-button {
	z-index: 1;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
}

.screen-loading {
	z-index: 100;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	transform: -webkit-translate(-50%, -50%);
	transform: -moz-translate(-50%, -50%);
	transform: -ms-translate(-50%, -50%);
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	height: 100vh;
}

/* input[type="checkbox"] {
	margin-right: 1em;
} */

.optionsContainer {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.label {
	margin: 0;
	margin-left: 1em;
	
}
