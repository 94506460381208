*{
    background-color: transparent;
}
.main_container{
    position: fixed;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.377);
}

.notShow{
    display: none;
}

.frame{
    width:100%;
    height: 78%;
    border:none;
}

.modal{
    position: relative;
    background-color: white;
    width: 380px;
    overflow: auto;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.192), 0 7px 20px 0 rgba(0, 0, 0, 0.192);
}

.header{
    z-index: 1;
    position: sticky;
    display: flex;
    top: 0;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #F5F5FF;
    padding-left: 15px;
    padding-right: 15px;
    height: 50px;
}

.exit_btn{
    color: #000000;
    border: transparent;
    font-weight: bolder;
    font-family: Mulish;
    font-size: 18px;
    cursor: pointer;
}

.exit_btn:hover{
    color: #1f0770;
}

.header_text{
    color:#3C3B6E;
    font-family: Mulish;
    font-weight: bold;
    font-size: 15px;
}

.section_container{
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #3c3b6e2c;
}

.section_title{
    color:#3C3B6E;
    font-family: Mulish;
    font-size: 15px;
    width: 100%;
    text-align: left;
}

.section_content{
    color:#3C3B6E;
    font-family: Mulish;
    font-size: 14px;
    width: 100%;
    text-align: left;
    margin-top: -5px;
}

.selected_date{
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    margin: 0px;
}

.timeOptions_container{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.timeOption_btn{
    border: 1px solid #2B08A0;
    border-radius: 30px;
    display:flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px 12px;
    color: #2B08A0;
    background-color: white;
}

.timeOnlyOption_btn{
    color: white;
    padding: 8px 20px;
    border: 1px solid #2B08A0;
    border-radius: 50px;
    background-color: #2B08A0;

    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    cursor: pointer;
}

.close_btn_container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 15px;
    z-index: 10;
    position: sticky;
    bottom: 0px;
}

.close_btn{
    background-color: #2B08A0;
    border: 2px solid #2B08A0;
    border-radius: 50px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding: 8px 20px;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 5px 13px rgb(0 0 0 / 0.25);
}

.save_btn_container{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 15px;
    z-index: 10;
    position: sticky;
    bottom: 0px;
}

.save_btn{
    background-color: #2B08A0;
    border: 2px solid #2B08A0;
    border-radius: 5px;
    width: 90%;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    padding: 8px 20px;
    color: #FFFFFF;
    cursor: pointer;
    box-shadow: 0 5px 13px rgb(0 0 0 / 0.25);
}

.message{
    color: red;
    padding-top: 10px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
}

.calendar{
    border: 1px solid #2B08A0;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    margin-top: 15px;
}

.calendarTile{
    color: #3C3B6E;
    font-family: Mulish;
    font-weight: bold;
    font-style: normal;
}

.save_btn:hover{
    background-color: #1f0770;
    border: 2px solid #1f0770;
}

@media (min-width: 767.98px) {
    .modal{
        width: 600px;
    }
}

@media (max-width: 767.98px) {
    .modal{
        width: 550px;
    }
}

@media (max-width: 575.98px) {
    .modal{
        width: 350px;
        max-height: 650px;
    }
    .selected_date{
        font-size: 12px;
    }
    .calendar{
        width: 100%;
    }
    .section_content{
        font-size: 12px;
    }
}

@media (max-width: 360px) {
    .modal{
        width: 280px;
        max-height: 600px;
    }
    .header_text{
        font-size: 12px;
    }
    .section_title{
        font-size: 12px;
    }
    .exit_btn{
        font-size: 15px;
    }
    .selected_date{
        font-size: 12px;
    }
    .header{
        padding-left: 10px;
        padding-right: 10px;
    }
    .timeOption_btn{
        padding: 5px 10px;
        margin-left: 5px;
        margin-right: 5px;
        border-radius: 20px;
    }
    .timeOptions_container{
        width: 100%;
    }
    .calendar{
        font-size: 8px;
    }
}

@media (max-width: 320px) {
    .modal{
        width: 260px;
        max-height: 560px;
    }
    .timeOption_btn{
        padding: 5px 10px;
        margin-left: 5px;
        margin-right: 5px;
        font-size: 12px;
        line-height: 12px;
        border-radius: 20px;
    }
    .selected_date{
        font-size: 12px;
    }
    .save_btn{
        font-size: 12px;
    }
    .calendar{
        font-size: 8px;
    }
}
