.main-container {
  display: flex;
  background-color: #ffffff;
  /* padding-left: 3em;
  padding-right: 3em; */
  flex-direction: column;
  justify-content: space-evenly;
  /* height: 100%; */
  overflow-y: hidden;
  max-width: 550px;
	width: 90%;
  flex: 1;
}

.grunner-input-field {
  padding-top: 1em;
}

.next-button {
  z-index: 1;
  /* position: absolute; */
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  width: 90%;
  height: 55px;
  max-width: 550px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.disabled-next-button {
  z-index: 1;
  /* position: absolute; */
  bottom: 2rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  background-color: #bab9b9;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  width: 90%;
  height: 55px;
  max-width: 550px;
  display: inline;
}

.loading {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}