.main-container{
    display:flex;
    background-color: #ffffff;
    padding-bottom:5em;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
}
.spinner{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tasks-text-container{
    margin-top:14vh;
    width: 90%;
    max-width:90%;
    background-color: #eee;
    padding: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-self: center;
}
.tasks-text-title{
    font-family: "Mulish";
    font-size: 20px;
    margin: 0;
    text-align: left;
    margin-bottom: 3%;
}
.tasks-text{
    font-family: "Mulish";
    font-size: 18px;
    margin: 0;
}

