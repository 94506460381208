.info-container {
    height: 100vh;
    position: relative;
    border: 3px solid;
}

.info-title {
    margin: 0;
    position: absolute;
    font-size: 150%;
    top: 32.5%;
    text-align: center;
    left: 50%;
    -ms-transform: translate(-50%, -67.5%);
    transform: translate(-50%, -67.5%);
}

.info-message {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
}

.info-button {
    margin: 0;
    position: absolute;
    top: 67.5%;
    left: 50%;
    -ms-transform: translate(-50%, -32.5%);
    transform: translate(-50%, -32.5%);
}