.main_container{
    /* position:sticky; */
    top: 0;
    left:0;
    min-height: 100vh;
    /* max-height: 100vh; */
    /* overflow-y:scroll; */
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.form_container {
    margin-top: 1vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;  
    align-items: center;
    max-width: 500px;
    max-height: 100vh;
    padding-top: 20px;
    width: 100%;
    /* background-color: white;
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.096), 0 7px 20px 0 rgba(0, 0, 0, 0.096); */
  }

  .header{
    z-index: 1;
    position: -webkit-sticky;
    position: sticky;
    display: flex;
    
    justify-content: flex-start;
    width: 100%;
}

.exit_btn{
    color: black;
    border: transparent;
    font-weight: bolder;
    font-family: Mulish;
    font-size: 18px;
    cursor: pointer;
    padding: 0;
}

.exit_btn:hover{
    color: #1f0770;
}

  .address_input {
    font-family: Mulish;
    font-style: normal;
    width: 100%;
    background-color: white;
    height: 1.2rem;
    padding-top: 20px;
    padding-bottom: 15px;
    border: 0;
    border-bottom: 1px solid gray;
    font-size: 16px;
  }
  
  .address_input:focus {
    border-bottom: 2px solid #2B08A0;
    outline: none;
  }

.autocomplete_dropdown_container{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border: 1px solid gray;
    /* position: absolute;
    z-index: 2;
    max-height: 500px;
    max-width: 67vw; */
     overflow-y: hidden;

}

.full_field {
    flex: 400px;
    margin: 15px 0;
    margin-top: 2rem;
}

.place_hoder{
    font-family: Mulish;
    font-size: 15px;
}

.form_label{
    width: 100%;
    font-size: 16px;
    font-family: Mulish;
    font-style: normal;
    font-weight: bold;
    background-color: white;
}

.slim_field_left {
    flex: 1 150px;
    margin: 15px 15px 15px 0px;
  }
  
.slim_field_right {
    flex: 1 150px;
    margin: 15px 0px 15px 15px;
}

.btn_container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    padding: 5px;
    background-color: white;
}

.save_btn{
  background-color: #2B08A0;
  border: 2px solid #2B08A0;
  width: 100%;
  border-radius: 5px;
  font-family: Mulish;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  text-align: center;
  padding: 8px 20px;
  margin-top: 10px;
  color: #FFFFFF;
  cursor: pointer;
}

.save_btn:hover{
  background-color: #554dc9;
  border: 2px solid #554dc9;
}

.logo {
  text-align: right;
  padding: 0.25rem 0.5rem;
  list-style-type: none;
}

@media (max-width: 400px) {
  .full_field {
    flex: 400px;
    margin: 5px 0;
    margin-top: 2rem;

}
  .slim_field_left {
    flex: 400px;
    margin: 5px 0;
  }
  .slim_field_right {
    flex: 400px;
    margin: 5px 0;
  }
  
  .form_label{
    font-size: 16px;
  }

  .save_btn{
    font-size: 15px;
    padding: 5px 16px;
  }
}