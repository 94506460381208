.edit-card {
	position: absolute;
	z-index: 100;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: min(100%, 500px);
	background-color: white;
	box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.35);
	height: 100%;
	overflow: auto;
}

.header-container {
	padding: 10px;
}

.close-button {
	z-index: 6;
	position: absolute;
	top: 10px;
	left: 10px;
}

.header-text {
	font-size: 20px;
	font-family: "Mulish";
	font-weight: bold;
}

.content-container {
	padding-left: 15px;
	padding-right: 10px;
	padding-top: 50px;
	background-color: transparent;
	height: 100%;
	overflow: auto;
}

.input-field {
	font-size: 18px;
	border-radius: 5px;
	border: 1px solid black;
	padding: 10px;
	height: 50%;
	width: 100%;
	background-color: white;
}

.save-button {
	z-index: 1;
	position: absolute;
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
}

.fix-safari-position {
	overflow: hidden;
	height: 100%;
	width: 100%;
	position: fixed;
}

.image-container {
	display: flex;
}

.preview-image {
	height: 40px;
	width: 100%;
	object-fit: cover;
	position: relative;
	border-radius: 10px;
}

.design-title {
	display: block;
	font-size: 1em;
	text-align: center;
	font-family: "Mulish";
}

.section-header {
	display: block;
	font-size: 1.2em;
	font-weight: bolder;
	font-family: "Mulish";
}

.design-edit-card-header {
	position: fixed;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: white;
	height: 40px;
}

/*Fix the OrderHistory.module.css!!!*/
.section-selector:hover,
.section-selector:focus {
	background: initial !important;
}
.design-section {
	list-style-type: none;
}
.section-selector {
	list-style-type: none;
}







.design-section:hover,
.design-section:focus {
	background-color: grey;
	padding: 8px 12px;
}
