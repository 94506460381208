.mainContainer {
	width: 100%;
	height: 100%;
	background: "#fff";
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: "Mulish";
	justify-content: space-between;
	flex-shrink: 0;
}

.loading {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100vh;
}

.logoContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	--webkit-flex: 0;
	flex-shrink: 0;
}

.logo {
	width: 80px;
	height: 80px;
	margin-top: 25px;
	margin-bottom: 15px;
}

.copyBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 170px;
	min-height: 50px;
	background: #2b08a0;
	border-radius: 10px;
	cursor: pointer;
	margin-bottom: 7%;
}
.copiedBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 170px;
	min-height: 50px;
	background: #a00831;
	border-radius: 10px;
	cursor: pointer;
	margin-bottom: 7%;
}

.copyBtnText {
	color: #ffffff;
	font-family: "Mulish";
	font-weight: 500;
	font-size: 18px;
	line-height: 25px;
	text-align: center;
	letter-spacing: -0.151515px;
}

.textContainer {
	font-family: "Mulish";
	font-size: 15px;
	--webkit-flex: 0;
	flex-shrink: 0;
}
.code {
	font-family: "Mulish";
	font-size: 25px;
	padding: 5% 0;
	word-break: break-all;
	width: 90%;
	text-align: center;
	height: auto;
}
.hr {
	border-style: none;
	border-top-style: dotted;
	border-color: rgb(195, 193, 193);
	border-width: 3px;
	width: 90%;
}
.hrLine {
	border-style: none;
	border-top-style: solid;
	border-color: rgb(151, 149, 149);
	border-width: 2.5px;
	width: 30%;
	margin: 1% 0;
}
.nextStepsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: auto;
	--webkit-flex: 0;
	flex-shrink: 0;
}

.stepsContainer {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 90%;
	padding: 5% 0 5% 0;
	height: auto;
	min-height: 60px;
	--webkit-flex: 0;
	flex-shrink: 0;
}
.mainTitle {
	color: #000;
	font-family: "Mulish";
	font-size: 20px;
	font-weight: 700;
	margin-top: 5%;
	height: auto;
}
.stepsTitle {
	color: #2b08a0;
	font-family: "Mulish";
	font-size: 18px;
	font-weight: 700;
	height: auto;
}
.stepsText {
	color: #000;
	font-family: "Mulish";
	text-align: center;
	height: auto;
}

.storeText {
	text-align: center;
	font-family: "Mulish";
}

.links {
	display: flex;
	gap: 20px;
	width: min(100%, 320px);
	margin: 3% auto;
	height: auto;
}

.storeLogos {
	width: 100%;
}

.noteContainer {
	display: flex;
	width: 90%;
	/* margin-top: 7%; */
	align-items: center;
	justify-content: center;
	height: auto;
	min-height: 40px;
	--webkit-flex: 0;
	flex-shrink: 0;
}
.noteTitle {
	font-family: "Mulish";
	height: auto;
	text-align: center;
}
.noteText {
	font-family: "Mulish";
	height: auto;
}

.linksContainer {
	width: 90%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 5%;
	height: auto;
	min-height: 40px;
	--webkit-flex: 0;
	flex-shrink: 0;
}

.link {
	padding: 0 2%;
	font-size: 13px;
	height: auto;
	margin: 0;
}

.aLink{
	color: #2b08a0;

}
.aLink:hover,
.aLink:focus,
.aLink:active {
	color: #2b08a0;
	text-decoration: underline;
}
/*  Small devices (landscape phones, less than 540px) */
@media (max-width: 540px) {
	.logo {
		width: 60px;
		height: 60px;
	}
}

@media (max-width: 360px) {
	
	.aLink {
		font-size: 15px;
		height: auto;
	}
}
