.modal {
	position: relative;
	background-color: white;
	width: 550px;
	/* height: 580px; */
	overflow: auto;
	box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.192),
		0 7px 20px 0 rgba(0, 0, 0, 0.192);
}

.section_title {
	color: #3c3b6e;
	font-family: Mulish;
	font-weight: bold;
	font-size: 15px;
	width: 100%;
	text-align: left;
	padding-top: 10px;
}

.section_container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

.info_title {
	font-family: Mulish;
	font-style: normal;
	font-size: 18px;
	text-align: start;
	/* width: 100%; */
	color: #2b08a0;
	margin-bottom: 0px;
}
.infoIcon {
	width: 18px !important;
	height: 18px;
}

.phoneNum_wrap {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	flex-wrap: wrap;
	border-bottom: 1px solid #2b08a0;
}

.phoneNum {
	display: flex;
	align-items: center;
}

.sendbtn {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: -10px;
}
.sendbtn2 {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	margin-bottom: -10px;
	margin-bottom: 1rem;
}

.Btn {
	background: #2b08a0;
	border-radius: 50px;
	border-style: none;
	font-family: Mulish;
	font-weight: bold;
	font-size: 12px;
	line-height: 22px;
	text-align: center;
	color: #ffffff;
	padding: 8px 25px;
	cursor: pointer;
	margin-bottom: 15px;
	margin-top: 10px;
	width: 100%;
}

.Btn:hover {
	background-color: #1f0770;
}

.email_input {
	font-family: Mulish;
	color: #3c3b6e;
	font-style: normal;
	width: 100%;
	background-color: white;
	height: 1.2rem;
	margin-top: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 0;
	font-size: 16px;
	padding-left: 0;
}

.phone_input {
	font-family: Mulish;
	font-style: normal;
	color: #3c3b6e;
	width: 100%;
	background-color: white;
	height: 1.2rem;
	margin-top: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 0;
	font-size: 16px;
	padding-left: 0;
}

.phone_input:focus {
	outline: none;
}

.sms_input {
	font-family: Mulish;
	color: #3c3b6e;
	font-style: normal;
	width: 370px;
	background-color: white;
	height: 1.2rem;
	margin-top: 0;
	padding-top: 20px;
	padding-bottom: 20px;
	border: 0;
	font-size: 16px;
	padding-left: 0;
}

.sms_input::placeholder {
	font-size: 16px;
}

.sms_input:focus {
	outline: none;
}

.underline {
	text-decoration: underline;
	cursor: pointer;
}

.policy_text {
	color: #3c3b6e;
	font-family: Mulish;
	text-align: center;
	font-size: 10px;
}

.modalContainer {
	/* position: fixed;
	z-index: 100;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	width: min(100%, 500px);
	height: 100%;
    background-color: gray; */
	position: fixed;
	z-index: 100;
	left: 0;
	right: 0;
	top: 0;
	margin: auto;
	/* width: min(100%, 500px); */
	height: 100%;
	display: flex;
	width: 100%;
    background-color: rgba(0, 0, 0, 0.377);
	align-items: center;
	justify-content: center;
}

.contentContainer {
	z-index: 120;
	top: 30%;
	/* left: min(5%, 250px); */
	position: absolute;
	width: min(90%, 500px);
	height: max-content;
	padding: 20px;
	background-color: #f0f2ff;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
}

.modalText {
	color: #434343;
	font-family: "SF Pro Text";
	font-size: 16px;
	text-align: center;
	letter-spacing: 0.02em;
	padding: 5px;
	margin-top: 5%;
	font-family: "Mulish";
}

.closeIcon {
	margin: 0 auto;
	border: none;
	position: absolute;
	right: 5;
	top: 5;
}

@media (max-width: 575.98px) {
	.modal {
		/* width: 350px; */
		/* height: 580px; */
		width: 90%;
	}
	.info_title {
		font-size: 16px;
	}
	.Btn {
		padding: 5px 12px;
		font-size: 12px;
	}
	.phone_input::placeholder {
		font-size: 14px;
	}
	.sms_input::placeholder {
		font-size: 14px;
	}
	.email_input::placeholder {
		font-size: 14px;
	}
}

@media (max-width: 360px) {
	.modal {
		/* width: 280px; */
		/* height: 540px; */
		width: 90%;
	}
	.Btn {
		font-size: 10px;
	}
	.section_title {
		font-size: 12px;
	}
	.info_title {
		font-size: 13px;
	}
	.phone_input {
		width: 150px;
	}
	.phone_input::placeholder {
		font-size: 12px;
	}
	.sms_input::placeholder {
		font-size: 12px;
	}
	.email_input::placeholder {
		font-size: 12px;
	}
}
