.edit-card {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: min(100%, 500px);
  height: 100%;
  background-color: white;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.35);
  overflow: auto;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.edit-card::-webkit-scrollbar {
  display: none;
}


.header-container {
  z-index: 50;
  padding: 10px;
  background-color: white;
}

.close-button {
  z-index: 6;
  position: absolute;
  top: 10px;
  left: 5%;
}

.header-text {
  position: absolute;
  top: 12px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-size: 20px;
  font-family: 'Mulish';
  font-weight: bold;
}

.content-container {
  height: 100vh;

}

.selfie-preview {
  display: block;
  margin: 15px auto;
  width: min(75vw, 350px);
  height: min(75vw, 350px);
  object-fit: cover;
  border-radius: 100%;
  border: 3px solid #2b08a0;
}

.input-field {
  font-size: clamp(12px, 5vw, 20px);
  border-radius: 0;
  border-top: 0;
  border-bottom: 3px solid #2b08a0;
  border-left: 0;
  border-right: 0;
  padding: 10px 0;
  width: 100%;
  background-color: white;
  margin: 15px 0;
}

.custom-image-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  width: 90%;
  height: 55px;
  margin-bottom: 20px;
  margin-top: 10%;
  flex-shrink: 0;
}

.custom-image-upload1{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  width: 90%;
  height: 40px;
  margin-bottom: 10px;
  flex-shrink: 0;
}

.custom-image-upload-position {
  min-height: calc(55px*4);
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  flex-shrink: 0;
  background-color: white;
}

.default-image-upload {
  display: none;
}

.save-button {
  /* z-index: 1; */
  /* position:relative;
  margin-top: 100%; */
  /* position: absolute;
  bottom: 20px;
  left:0;
  right: 0;
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  width: 90%;
  height: 55px; */
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 20px;
  height: 40px; 
  padding: 5px 15px;
  position: absolute;
  top: 10px;
  right: 5%;
}

.header{
  height: 55px;
}

.custom-image-upload-position1{
 position: relative;
 margin-top: 50px;
}

.ask-selfie{
  position: absolute;
  top: calc(50% - 55px*2);
  color:blue;
  text-decoration: underline;
  width: 90%;
  text-align: right;
  font-size: 15px;
}

.ask-selfie1{
  position: absolute;
  top: calc(50% - 40px*2);
  color:blue;
  text-decoration: underline;
  width: 90%;
  text-align: right;
  font-size: 15px;
}

@media screen and (max-width: 300px) {
  .save-button {
    font-size: 16px;
    padding: 3px 8px;
  }
  .custom-image-upload {
    font-size: 16px;
    text-align: center;
  }
  .custom-image-upload1 {
    font-size: 16px;
    text-align: center;
  }
}