.main-container {
	display: flex;
	/* height: 100%; */
	/* padding-top: 20%; */
	padding-top: 5%;
	background-color: #ffffff;
	/* padding-left: 3em;
	padding-right: 3em; */
	flex-direction: column;
	justify-content: space-around;
	max-width: 550px;
	width: 90%;
	flex: 1;
}

.next-button {
	z-index: 1;
	/* position: absolute; */
	bottom: 20px;
	left: 0;
	right: 0;
	margin: 0 auto;
	color: white;
	background-color: #2b08a0;
	border: none;
	border-radius: 5px;
	font-family: "Mulish";
	box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
	font-size: 20px;
	width: 90%;
	height: 55px;
	max-width: 500px;
	bottom: 2rem;
	align-items: flex-end;
}

.loading {
	display: flex;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
}