.feed-chat-modal-buttons-container {
    /* margin-bottom: 20px; */
}

.feed-chat-modal-container {
  width: 100%;
  border-radius: 0px;
}

.feed-chat-modal-container .modal-content,
.feed-chat-modal-container .modal-footer {
  background-color: #fff;
  border: none;
  border-radius: 20px;
}

.feed-chat-modal-container .modal-dialog {
  padding: 16px;
}

.feed-chat-modal-container .modal-dialog .modal-content .modal-header {
  background-color: transparent;
  justify-content: flex-start;
  border-bottom: solid 1px #ebeaeb;
  padding: 10px 16px !important;
}

.feed-chat-modal-container .modal-dialog .modal-content .modal-header span {
  font-size: 28px;
  font-weight: 800;
  flex: 1;
  text-align: center;
}

.feed-chat-modal-container
  .modal-dialog
  .modal-content
  .modal-header
  button.close {
  background-color: white;
  font-size: 24px;
  border-radius: 32px;
  width: 32px;
  height: 32px;
  opacity: 1;
  transition: all ease-in-out 0.3s;
  margin-right: 0px !important;
}

.feed-chat-modal-container
  .modal-dialog
  .modal-content
  .modal-header
  button.close:hover {
  transform: scale(1.2);
}

.feed-chat-modal-container .modal-body {
  padding: 0;
}

.feed-chat-modal-container .feed-full-image {
  width: 100%;
  margin: 0 auto;
}

.feed-chat-iframe {
    transition: all 0.3s ease-in-out;
}