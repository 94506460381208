.edit-card {
  position: absolute;
  z-index: 100;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: min(100%, 500px);
  height: 100%;
  background-color: white;
  box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.35);
}

.header-container {
  padding: 10px;
}

.close-button {
  z-index: 6;
  position: absolute;
  top: 10px;
  left: 10px;
}

.header-text {
  font-size: 20px;
  font-family: 'Mulish';
  font-weight: bold;
  padding-left: 15px;
}

.content-container {
  padding: 50px 0px;
}

.pen-color-picker {
  margin: 0 auto;
}

.canvas-container {
  position: relative;
}

.signature-canvas {
  display: block;
  margin: 10px auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.clear-button {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  padding: 5px 10px;
}

.save-button {
  z-index: 1;
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: white;
  background-color: #2b08a0;
  border: none;
  border-radius: 5px;
  font-family: 'Mulish';
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.35);
  font-size: 18px;
  width: 90%;
  height: 55px;
}
