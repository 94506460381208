/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal-dialog .modal-content {
  border-radius: 20px;
}

.modal-dialog .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px;
}

.block-user-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.modal-title {
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
}

.block-user-reason {
  margin-bottom: 16px;
}

.block-user-reason,
.block-user-reason-id,
.block-user-reason button {
  width: 100%;
  text-align: left;
}

.block-user-reason button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block-user-text-area .input,
.block-user-reason button {
  background-color: #f8f8f9;
  color: #a1a0a2;
  border: none;
  font-size: 18px;
}

.block-user-reason-id.show .btn-primary.dropdown-toggle,
.block-user-reason button:hover,
.block-user-reason button:focus,
.block-user-reason button:active {
  background-color: #fff;
  color: #a1a0a2;
  border: none;
  font-size: 18px;
}

.block-user-reason .dropdown-menu,
.block-user-reason .dropdown-menu .dropdown-item {
  width: 100%;
  font-size: 18px;
}

.block-user-reason .dropdown-menu {
  border-radius: 20px;
  padding: 0px 16px;
}

.block-user-reason .dropdown-menu .dropdown-item:hover,
.block-user-reason .dropdown-menu .dropdown-item {
  text-decoration: none;
  border-bottom: 1px solid #d9d9d9;
  padding: 16px 0px;
  background-color: #fff;
  color: #000;
}

.block-user-reason .dropdown-menu .dropdown-item:last-child {
  border-bottom: none;
}

.block-user-text-area .input {
  padding: 16px;
}

.block-user-text-area {
  width: 100%;
}

.modal-header button.close {
  padding: 0;
  margin: 1rem !important;
}

.modal-body {
  padding: 0;
}

.block-user-input-text {
  resize: none;
}

.block-user-input-text:active,
.block-user-input-text:focus {
  border: 0;
  outline: 0;
}

/* buttons */
.block-user-each-button,
.block-user-each-button:active,
.block-user-each-button:focus {
  border: 0;
  outline: 0;
  color: #fff;
  padding: 2% 5vw;
  border-radius: 5px;
}

.block-user-each-button:disabled {
  cursor: not-allowed;
  color: #a1a0a2;
}

.block-user-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2%;
}

.input {
  position: relative;
}

.input-text {
  display: block;
  margin: 0;
  padding: var(--inputPaddingV) var(--inputPaddingH);
  color: inherit;
  width: 100%;
  font-family: inherit;
  font-size: var(--inputFontSize);
  font-weight: inherit;
  line-height: var(--inputLineHeight);
  border: 0;
  transition: box-shadow var(--transitionDuration);
  resize: none;
}

.input-text::placeholder {
  color: #b0bec5;
}

.input-text:focus {
  outline: none;
}

.modal-footer.report-footer-area {
  justify-content: center;
}

.report-modal-gfeed {
  background-color: transparent !important;
}

.modal-footer {
  padding-top: 0.75rem !important;
}

.report-modal-form-gfeed {
  margin: 0;
}

.block-user-each-button,
.block-user-each-button:active,
.block-user-each-button:focus {
  border: 0;
  outline: 0;
  color: #000;
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  width: 100%;
}

.block-user-modal-login-text {
  font-size: 16px;
  color: #000;
  text-align: center;
  padding: 20px 8px 8px;
  margin-bottom: 0px;
}

.block-user-each-button.cancel {
  color: red;
}

/* mobile */
@media (max-width: 600px) {
  div.modal-title.h4 {
  }

  .report-modal-form-gfeed {
    margin: 0;
  }
}

/* desktop */
@media (min-width: 601px) {
  div.modal-title.h4 {
    margin: 0 auto;
    padding-left: 3vw;
  }

  .report-modal-form-gfeed {
    margin: 0;
  }
}
