.backdrop {
  z-index: 6;
  position: absolute;
  top: 0;
  left: 0;
  height: max(100%, 100vh);
  width: max(100%, 100vw);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
