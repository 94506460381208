@font-face {
    font-family: 'SF Pro Text';
    src: url('../../assets/fonts/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.linkCreatedImg{
    width: 400px;
    margin-bottom: 25px;
}

.linkReadyImg{
    width: 210px;
    height: 160px;
    margin-bottom: 35px;
}

.outer{
    display: flex;
    justify-content: center;
    min-height: 80vh;
    width: 100%;
    align-items: center;
    flex-shrink: 0;
    position: relative;
    flex-direction: column;
}

.container{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    width: 100%;
    height: 500px; 
}

.text{
    width: 200px;
    height: 34px;
    letter-spacing: 0.02em;
    font-size: 18px;
    line-height: 17px;
    text-align: center;
    font-family: 'SF Pro Text';
    color: #000000;
}

.linkReadytext{
    height: 24px;
    letter-spacing: 0.02em;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    font-family: 'SF Pro Text';
    color: #000000;
}

.linkBox{
    width: 360px;
    background: #E6E6E6;
    border-radius: 5px;
    margin-top: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 6px;
    height: max-content;
    padding:10px;
}

.link{
    font-family: 'SF Pro Text';
    color: #000000;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.02em;
    -webkit-appearance: none;
    border: 0;
    width: 100%;
    pointer-events:none;
    white-space: pre-line;
}
/* 
input:focus, textarea:focus, select:focus{
    outline: none;
}

::selection {
    color: none;
    background: none;
}

::-moz-selection {
    color: none;
    background: none;
} */

.copyBtn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 360px;
    height: 60px;
    background: #2B08A0;
    border-radius: 10px;
    margin-bottom: 45px;
    cursor: pointer;
    /* -webkit-transition-duration: 0.4s; /* Safari */
    /* transition-duration: 0.4s;
    position: relative;
    overflow: hidden; */
} 

.copyBtn:active{
    background: #1a0461;
} 

/* .tooltip {
    position: relative;
    display: inline-block;
}
  
.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgb(153, 153, 153);
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 200%;
    left: 50%;
    margin-left: -60px;
  
}
  
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent rgb(153, 153, 153) transparent;
} */

.copyBtnText{
    color: #FFFFFF;
    font-family: 'Mulish';
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: center;
    letter-spacing: -0.151515px;
}

.notRedeemed{
    color: #EF0000;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
}

.redeemed{
    color: #009F2D;
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.01em;
}

  .modal{
    display: none; /* Hidden by default */
    position: absolute; 
    z-index: 1; 
    align-items: center;
    justify-content: center;
    left: 0;
    top: 0;
    padding-top: 200px;
    width: 100%;
  }
  
  .modalContent {
    /* background-color:#F0F2FF;
    width: 90%;
    max-width: 600px;
    height: 320px;
    padding: 30px 20px 20px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow: auto; */
    position: relative;
    width: 90%;
    height: 300px;
	margin-top: 10px;
	display: flex;
    overflow: auto;
	align-items: center;
    background-color: #F0F2FF;
    padding: 40px 20px 20px 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
  }

  .modalContent::-webkit-scrollbar {
	display: none;
  }

  .buffer{
      display: flex;
      min-height: min-content;
      overflow: hidden;
  }
  
.content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    margin-top: 20px;
    background-color: #F0F2FF;
}
  
  .modalHeader{
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.02em;
    font-family: 'SF Pro Text';
    color: #4E4E4E;
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
  }
  
  .contentText{
    color: #8C8C8C;
    font-family: 'SF Pro Text';
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    letter-spacing: 0.02em;
  }
  
  .close {
    color: #aaaaaa;
    position:absolute;
    width: 30px;
    top:15px;
    right:15px;
    /* position: fixed;
    top: 225px !important; */
    /* left: auto !important; */
    cursor:pointer;
}

@media (max-width: 420px) {

}

@media (max-width: 420px) {
    .linkReadyImg{
        width: 200px;
        height: 150px;
    }
    .linkCreatedImg{
        width: 330px;
    }
    
    .text{
        width: 180px;  
        font-size: 16px;
    }

    .linkReadytext{
        font-size: 18px; 
    }

    .linkBox{
        width: 90%;
    }

    .copyBtn{
        width: 90%;
    }
    .link{
        font-size: 14px;
    }
    .copyBtnText{
        font-size: 18px;
    }
    .modalHeader{
        font-size: 16px;
        padding-bottom: 10px;
        }
    
    .contentText{
        font-size: 14px;
    }
    .modalContent {
        height: 270px;
    }
    .close {
        width: 25px;
    }
}

@media (max-width: 300px) {
    .StyledPage{
        height: 650px;
    }
    .linkReadyImg{
        width: 160px;
        height: 120px;
        margin-bottom: 25px;
    }

    .linkCreatedImg{
        width: 200px;
    }
    .text{
        width: 132px;
        font-size: 14px;
    }
    .linkReadytext{
        font-size: 16px; 
    }
    .link{
        font-size: 12px;
    }
    .copyBtnText{
        font-size: 16px;
    }
    .notRedeemed{
        font-size: 12px;
    }
    .redeemed{
        font-size: 12px;
    }
    .modalContent {
        height: 250px;
        padding: 30px 20px 20px 20px;
      }
      
    .modalHeader{
    font-size: 16px;
    padding-bottom: 10px;
    }

    .contentText{
    font-size: 12px;
    }
}