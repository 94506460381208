@font-face {
	font-family: 'SF Pro Display light';
	src: url('../../assets/fonts/SF-Pro-Display-Light.otf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.headerTitles {
	margin: 0% 0% 0% 0%;
	/* padding: 3% 0% 1% 0%; */
	position: sticky;
	width: 100%;
	top: 50px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	z-index: 10;
}

.linksContainer {
	display: flex;
	cursor: pointer;
	align-items: center;
	justify-content: space-between;
	border-radius: 5px;
	width: 100%;
	height: 40px;
	padding: 0 3px;
	background-color: #E6E6E6;
}

.flexContainer {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100px;
	padding:0 15px;
}

.filtersContainer{
	height: 50px;
	width: 100%;
	margin-top: 10px;
	display: flex;
    overflow: auto;
	align-items: center;
}

.filtersContainer::-webkit-scrollbar {
	display: none;
  }

.box{
	display: flex;
    min-height: min-content;
}

.filter{
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #D4D9FF;
	border-radius: 30px;
	color: black;
	font-family: 'SF Pro Display light', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	width: 140px;
	height: 40px;
	margin-right: 10px;
	letter-spacing: 0.01em;
	cursor: pointer;
}

.activeFilter{
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 30px;
	background-color: #6C40FC;
	color: white;
	font-family: 'SF Pro Display light', sans-serif;
	font-style: normal;
	font-weight: 300;
	font-size: 16px;
	line-height: 19px;
	width: 140px;
	height: 40px;
	margin-right: 10px;
	letter-spacing: 0.01em;
	cursor: pointer;
}

.link {
	color: #8C8C8C;
	width: 50%;
	text-decoration: none;
	line-height: 10px;
	/* font-size: 12px; */
	font-style: normal;
	/* font-weight: 700; */
	display: flex;
	align-items: center;
	justify-content: space-evenly;
}

.activeClass {
	background: white;
	border-radius: 5px;
	width: 50%;
	height: 34px;
	/* font-family: Montserrat;
	font-size: 12px; */
	font-style: normal;
	/* font-weight: 700; */
	color: #000000;
	display: flex;
	align-items: center;
	justify-content: center;
}

.headerTitle {
	font-family: 'Mulish', sans-serif;
	font-weight: 700;
	font-size: 16px;
}

@media (min-width: 650px) {
	.filtersContainer{
		justify-content: center;
		align-items: center;
	}
	.flexContainer {
		width: 100%;
	}
	.linksContainer {
		width: 60%;
	}
}

@media (max-width: 400px) {
	.headerTitle {
		font-size: 14px;
	}
	.filter{
		font-size: 14px;
		width: 120px;
	}
	
	.activeFilter{
		font-size: 14px;
		width: 120px;
	}
	.filtersContainer{
		margin-top: 5px;
	}
}

@media (max-width: 300px) {
	.headerTitle {
		font-size: 14px;
	}
	.filter{
		font-size: 14px;
		width: 100px;
	}
	
	.activeFilter{
		font-size: 14px;
		width: 100px;
	}
	.filtersContainer{
		margin-top: 5px;
	}
}

@media (max-width: 200px) {
	.headerTitle {
		font-size: 10px;
	}
	.filter{
		font-size: 10px;
		width: 80px;
	}
	
	.activeFilter{
		font-size: 10px;
		width: 80px;
	}
}
